<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['รายการแทง', 'ดูของรวม / คาดคะเนได้เสีย']"
    />
    <round-detail />
    <div class="alert alert-info" role="alert">
      <span class="text-danger">ตัวเลขสีแดง</span> = เต็มแล้ว,
      <span class="text-success">พื้นหลังสีเขียว</span> = ถูกรางวัล, กด Ctrl+F
      เพื่อค้นหา
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-show="!isLoading">
      <div class="d-flex align-items-center mb-2">
        <label for="viewMode" class="form-control-label mb-0 mr-2">ดู:</label>
        <select
          id="viewMode"
          class="form-control mr-2 w-auto"
          v-model="viewMode"
        >
          <option :value="0">ของรวม</option>
          <option :value="1">คาดคะเนได้เสีย</option>
        </select>
        <label for="sortMode" class="form-control-label mb-0 mr-2"
          >เรียง:</label
        >
        <select
          id="sortMode"
          class="form-control w-auto mr-2"
          v-model="sortMode"
        >
          <option value="predict_desc">คาดคะเนยอดเสีย มาก-&gt;น้อย</option>
          <option value="predict_asc">คาดคะเนยอดเสีย น้อย-&gt;มาก</option>
          <option value="take_desc">ยอดแทง มาก-&gt;น้อย</option>
          <option value="take_asc">ยอดแทง น้อย-&gt;มาก</option>
          <option value="num_asc">หมายเลข น้อย-&gt;มาก</option>
          <option value="num_desc">หมายเลข มาก-&gt;น้อย</option>
        </select>
        <label for="numberOfItem" class="form-control-label mb-0 mr-2"
          >จำนวนแถว:</label
        >
        <select
          id="numberOfItem"
          class="form-control w-auto"
          v-model.number="showNumberLength"
        >
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">ทั้งหมด</option>
        </select>
      </div>

      <ValidationObserver ref="observer">
        <table class="table table-sm table-bordered table-dark text-center">
          <thead>
            <tr>
              <th style="width: 150px"></th>
              <th v-for="item in categoryList" :key="item.id">
                {{ item.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>ซื้อ</span>
                  <span :class="getClassAmount(sumBuy)">
                    {{ util.formatMoney(sumBuy) }}
                  </span>
                </div>
              </td>
              <td
                class="bg-yellow"
                v-for="item in categoryList"
                :key="item.id"
                :class="
                  getClassAmount(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumBuyAmount
                  )
                "
              >
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumBuyAmount
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>คอม</span>
                  <span :class="getClassAmount(sumCommissionAmount)">
                    {{ util.formatMoney(sumCommissionAmount) }}
                  </span>
                </div>
              </td>
              <td
                class="bg-yellow"
                v-for="item in categoryList"
                :key="item.id"
                :class="
                  getClassAmount(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumCommissionAmount
                  )
                "
              >
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id)
                      .sumCommissionAmount
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>รับ</span>
                  <span :class="getClassAmount(sumReceive)">
                    {{ util.formatMoney(sumReceive) }}
                  </span>
                </div>
              </td>
              <td
                class="bg-yellow"
                v-for="item in categoryList"
                :key="item.id"
                :class="
                  getClassAmount(
                    findBetItemWaitingStatementsByCategoryId(item.id).sumReceive
                  )
                "
              >
                {{
                  util.formatMoney(
                    findBetItemWaitingStatementsByCategoryId(item.id).sumReceive
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between">
                  <span>จ่าย</span>
                  <span :class="getClassAmount(sumPay)">
                    {{ util.formatMoney(sumPay) }}
                  </span>
                </div>
              </td>
              <td
                class="bg-yellow"
                v-for="item in categoryList"
                :key="item.id"
                :class="getClassAmount(findSumPay(item))"
              >
                {{ util.formatMoney(findSumPay(item)) }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex justify-content-between align-items-center">
                  <span>ตั้งสู้</span>
                  <button class="btn btn-sm btn-primary" @click="save">
                    บันทึก
                  </button>
                </div>
              </td>
              <td v-for="item in categoryList" :key="item.id">
                <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    @keypress="$root.onlyNumber"
                    v-model.number="findBetReceiveCategory(item.id).amount"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </td>
            </tr>
          </tbody>
          <tfoot
            class="bg-light"
            style="line-height: 5px"
            v-if="betItemWaitings"
          >
            <tr v-for="index in showNumberLength" :key="index">
              <td class="text-black">{{ index }}</td>
              <template v-for="item in categoryList">
                <template
                  v-for="(betItem, index) in [
                    findBetItemWaiting(index, item.id),
                  ]"
                >
                  <td
                    :key="item.id + index"
                    :class="{ 'bg-success': betItem.sumPayAmountActual < 0 }"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="text-danger font-weight-bold">
                        <a
                          class="text-danger"
                          href="#"
                          @click.prevent="
                            $router.push(
                              `/items/of-number/${item.id}/${betItem.number}`
                            )
                          "
                        >
                          {{ betItem.number }}
                        </a>
                      </span>
                      <span
                        class="font-weight-bold"
                        :class="{
                          'text-black':
                            !viewMode ||
                            (viewMode && parseFloat(betItem.sumPayAmount) == 0),
                          'text-danger':
                            (viewMode &&
                              parseFloat(betItem.sumPayAmount) < 0) ||
                            findBetReceiveCategory(item.id).amount <=
                              betItem.sumBuyAmount,
                          'text-positive':
                            viewMode && betItem.sumPayAmountActual < 0,
                          'text-success':
                            viewMode && parseFloat(betItem.sumPayAmount) > 0,
                        }"
                      >
                        {{
                          viewMode
                            ? betItem.sumPayAmount
                              ? util.formatMoneyNormal(
                                  parseInt(betItem.sumPayAmount)
                                )
                              : ""
                            : betItem.sumBuyAmount
                            ? util.formatMoneyNormal(
                                parseInt(betItem.sumBuyAmount)
                              )
                            : ""
                        }}
                      </span>
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </tfoot>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped>
.min-width-th {
  min-width: 150px;
}

tfoot > tr > td {
  padding: 0.65rem !important;
}
</style>

<script>
export default {
  data() {
    return {
      sortMode: "predict_desc",
      viewMode: 0, // 0 = ดูของ, 1 คาดคะเนได้เสีย
      sumBuy: 0.0,
      sumCommissionAmount: 0.0,
      sumReceive: 0.0,
      sumPay: 0.0,
      isLoading: false,
      betItemWaitings: [],
      betItemWaitingStatements: [],
      betItemWaitingMaxBuys: [],
      categoryList: [],
      showNumberLength: 100,
      betReceiveCategorys: [],
      betItemWaitingsByCategory: new Map(),
      maxPayByCategoryId: new Map(),
    }
  },
  methods: {
    findBetReceiveCategory(categoryId) {
      let item = this.betReceiveCategorys.find(
        (item) => item.huayCategoryId == categoryId
      )
      return item
        ? item
        : {
            amount: 0,
            huayCategoryId: categoryId,
          }
    },
    findBetItemWaiting(index, categoryId) {
      let items = this.betItemWaitingsByCategory.get(categoryId)

      if (!items || index > items.length) {
        return {}
      }

      // if (items.length > this.showNumberLength) {
      //   this.showNumberLength = items.length
      // }

      return items[index - 1]
    },
    findSumPay(item) {
      return this.maxPayByCategoryId.get(item.id)
    },
    findMaxPayByCategoryId(categoryId) {
      let min = Math.min.apply(
        Math,
        this.betItemWaitings
          ?.filter((item) => item.categoryId === categoryId)
          .map(function (item) {
            return item.sumPayAmount
          })
      )

      return isFinite(min) ? min : 0
    },
    findBetItemWaitingStatementsByCategoryId(categoryId) {
      let item = this.betItemWaitingStatements?.find(
        (item) => item.categoryId == categoryId
      )
      return item
        ? item
        : {
            sumBuyAmount: 0.0,
            sumCommissionAmount: 0.0,
            sumReceive: 0.0,
          }
    },
    findBetItemWaitingMaxBuysByCategoryId(categoryId) {
      let item = this.betItemWaitingMaxBuys?.find(
        (item) => item.categoryId == categoryId
      )
      return item
        ? item
        : {
            maxPay: 0.0,
          }
    },
    getClassAmount(a) {
      if (parseFloat(a) == parseFloat(0)) {
        return ""
      }
      if (parseFloat(a) > parseFloat(0)) {
        return "positive"
      }
      return "negative"
    },
    async loadData() {
      this.isLoading = true
      try {
        let p1 = this.axios({
          method: "post",
          url: "bet/limit/statement",
          data: {
            huayRoundId: this.roundId,
          },
        })
        let p2 = this.axios.get("huay/category/list")
        let p3 = this.axios.get(
          "bet/limit-by-category/" + this.$store.state.auth.typeId
        )

        let [res1, res2, res3] = await Promise.all([p1, p2, p3])

        this.betReceiveCategorys = res3.data.data

        let data = res1.data.data

        this.categoryList = res2.data.data.list

        let betItemWaitingStatements = []
        this.categoryList?.forEach((item) => {
          let betItemWaitings = data.betItemWaitings?.filter(
            (item2) => item2.categoryId == item.id
          )
          if (betItemWaitings) {
            let betItemWaitingStatement = {
              categoryId: item.id,
              sumBuyAmount: 0.0,
              sumCommissionAmount: 0.0,
              sumReceive: 0.0,
            }

            betItemWaitings.forEach((item2) => {
              betItemWaitingStatement.sumBuyAmount += parseFloat(
                item2.sumBuyAmount
              )
              betItemWaitingStatement.sumCommissionAmount += parseFloat(
                item2.sumCommissionAmount
              )
              betItemWaitingStatement.sumReceive =
                betItemWaitingStatement.sumBuyAmount +
                betItemWaitingStatement.sumCommissionAmount
            })

            this.sumBuy += parseFloat(betItemWaitingStatement.sumBuyAmount)
            this.sumCommissionAmount += parseFloat(
              betItemWaitingStatement.sumCommissionAmount
            )
            betItemWaitingStatements.push(betItemWaitingStatement)
          }
        })

        data.betItemWaitings?.forEach((item) => {
          let betItemWaitingStatement = betItemWaitingStatements.find(
            (item2) => item2.categoryId === item.categoryId
          )
          if (betItemWaitingStatement) {
            item.sumPayAmount =
              parseFloat(item.sumPayAmount) + betItemWaitingStatement.sumReceive
          }
        })

        this.sumReceive = this.sumBuy + this.sumCommissionAmount

        this.betItemWaitingStatements = betItemWaitingStatements

        this.betItemWaitings = data.betItemWaitings
        this.betItemWaitingMaxBuys = data.betItemWaitingMaxBuys

        this.categoryList?.forEach((item) => {
          let sumPay = this.findMaxPayByCategoryId(item.id)

          this.maxPayByCategoryId.set(item.id, sumPay)
          this.sumPay += sumPay

          let betItems = this.betItemWaitings?.filter(
            (item2) => item2.categoryId === item.id
          )

          betItems?.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) > parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })

          this.betItemWaitingsByCategory.set(item.id, betItems)
        })

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async save() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "bet/limit-by-category",
          data: this.betReceiveCategorys,
        })

        await this.$swal({
          icon: "success",
          text: "ตั้งสู้สำเร็จ",
        })

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)

        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
  },
  computed: {
    roundId() {
      return this.$store.state.auth.roundId
    },
  },
  watch: {
    roundId() {
      this.loadData()
    },
    sortMode() {
      if (this.sortMode == "predict_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) > parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "predict_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumPayAmount) < parseFloat(b.sumPayAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "take_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumBuyAmount) < parseFloat(b.sumBuyAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "take_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseFloat(a.sumBuyAmount) > parseFloat(b.sumBuyAmount)
              ? 1
              : -1
          })
        })
      } else if (this.sortMode == "num_asc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseInt(a.number) > parseInt(b.number) ? 1 : -1
          })
        })
      } else if (this.sortMode == "num_desc") {
        this.categoryList.forEach((cat) => {
          let items = this.betItemWaitingsByCategory.get(cat.id)
          items.sort(function (a, b) {
            return parseInt(a.number) < parseInt(b.number) ? 1 : -1
          })
        })
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
