<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="breacrumbItems" />
    <user-nav name="ข้อมูลทั่วไป/เก็บของ"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="ข้อมูลทั่วไป"
              :active="!$route.query.page || $route.query.page == 'info'"
              @click="setPage('info')"
            >
              <b-card-text>
                <CDataTable
                  :items="members"
                  :fields="fieldsNormal"
                  :itemsPerPageSelect="{ label: 'จำนวนแถว' }"
                  :items-per-page="itemsPerPage"
                  :loading="tableLoading"
                  :noItemsView="{
                    noResults: 'ไม่พบข้อมูล',
                    noItems: 'ไม่พบข้อมูล',
                  }"
                  :sorter="false"
                  column-filter
                  pagination
                  hover
                  dark
                  border
                  size="sm"
                  add-table-classes="text-center"
                >
                  <template #username="{ item }">
                    <td>
                      <router-link
                        v-if="item.level == 'PARTNER' || item.level == 'AGENT'"
                        :to="{
                          name: 'EditMemberByUserId',
                          params: { userId: item.id },
                          query: { page: 'info' },
                        }"
                      >
                        {{ item.username }}
                      </router-link>
                      <span
                        v-if="item.level != 'PARTNER' && item.level != 'AGENT'"
                        >{{ item.username }}</span
                      >
                    </td>
                  </template>
                  <template #nameEdit="{ item }">
                    <td class="td-input">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="item.name"
                      />
                    </td>
                  </template>
                  <template #phoneNoEdit="{ item }">
                    <td class="td-input">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="item.phoneNo"
                      />
                    </td>
                  </template>
                  <template #statusEdit="{ item }">
                    <td class="td-input-status">
                      <select
                        class="form-control form-control-sm"
                        v-model="item.status"
                      >
                        <option value="ACTIVE">ปกติ</option>
                        <option value="INACTIVE">ระงับ</option>
                      </select>
                    </td></template
                  >

                  <template #save="{ item }">
                    <td>
                      <button class="btn btn-primary" @click="saveData(item)">
                        บันทึก
                      </button>
                    </td>
                  </template>
                </CDataTable>
              </b-card-text>
            </b-tab>
            <b-tab
              title="รหัสผ่าน"
              :active="$route.query.page == 'password'"
              @click="setPage('password')"
            >
              <b-card-text>
                <CDataTable
                  :items="members"
                  :fields="fieldsPassword"
                  :itemsPerPageSelect="{ label: 'จำนวนแถว' }"
                  :items-per-page="itemsPerPage"
                  :loading="tableLoading"
                  :noItemsView="{
                    noResults: 'ไม่พบข้อมูล',
                    noItems: 'ไม่พบข้อมูล',
                  }"
                  column-filter
                  pagination
                  hover
                  :sorter="false"
                  dark
                  border
                  size="sm"
                  add-table-classes="text-center"
                >
                  <template #username="{ item }">
                    <td>
                      <router-link
                        v-if="item.level == 'PARTNER' || item.level == 'AGENT'"
                        :to="{
                          name: 'EditMemberByUserId',
                          params: { userId: item.id },
                          query: { page: 'password' },
                        }"
                      >
                        {{ item.username }}
                      </router-link>
                      <span
                        v-if="item.level != 'PARTNER' && item.level != 'AGENT'"
                        >{{ item.username }}</span
                      >
                    </td>
                  </template>
                  <template #passwordEdit="{ item }">
                    <td class="td-input">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="item.password"
                        minlength="6"
                      />
                    </td>
                  </template>
                  <template #save="{ item }">
                    <td>
                      <button
                        class="btn btn-primary"
                        @click="savePassword(item)"
                      >
                        บันทึก
                      </button>
                    </td>
                  </template>
                </CDataTable>
              </b-card-text>
            </b-tab>
            <!-- <b-tab
              title="แบ่งหุ้น/เก็บของ"
              :active="$route.query.page == 'share'"
              @click="setPage('share')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <CDataTable
                    :items="members"
                    :fields="fieldsShare"
                    :itemsPerPageSelect="{ label: 'จำนวนแถว' }"
                    :items-per-page="itemsPerPage"
                    :loading="tableLoading"
                    :noItemsView="{
                      noResults: 'ไม่พบข้อมูล',
                      noItems: 'ไม่พบข้อมูล',
                    }"
                    column-filter
                    pagination
                    hover
                    :sorter="false"
                    dark
                    border
                  >
                    <template #username="{ item }">
                      <td>
                        <router-link
                          v-if="
                            item.level == 'PARTNER' || item.level == 'AGENT'
                          "
                          :to="{
                            name: 'EditMemberByUserId',
                            params: { userId: item.id },
                            query: { page: 'share' },
                          }"
                        >
                          {{ item.username }}
                        </router-link>
                        <span
                          v-if="
                            item.level != 'PARTNER' && item.level != 'AGENT'
                          "
                          >{{ item.username }}</span
                        >
                      </td>
                    </template>
                    <template #sharePercentKey="{ item }">
                      <td>
                        <select
                          v-if="
                            item.level == 'PARTNER' || item.level == 'AGENT'
                          "
                          class="form-control form-control-sm"
                          v-model="item.sharePercent"
                          v-on:change="
                            item.keepSharePercent = 100 - item.sharePercent
                          "
                        >
                          <option
                            v-for="(item2, index) in makeSharePercentItem(
                              item.sharePercent
                            )"
                            :key="index"
                            v-bind:value="item2"
                          >
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                    </template>
                    <template #keepSharePercentKey="{ item }">
                      <td>
                        <select
                          v-if="
                            item.level == 'PARTNER' ||
                            item.level == 'AGENT' ||
                            item.level == 'MEMBER'
                          "
                          class="form-control form-control-sm"
                          v-model="item.keepSharePercent"
                        >
                          <option
                            v-for="(item2, index) in makeKeepShareItem(item)"
                            :key="index"
                            v-bind:value="item2"
                          >
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                    </template>
                    <template #keepBetExceedKey="{ item }">
                      <td>
                        <div
                          class="form-check"
                          v-if="
                            item.level == 'PARTNER' ||
                            item.level == 'AGENT' ||
                            item.level == 'MEMBER'
                          "
                        >
                          <input
                            :id="`inputKeepShare${item.id}`"
                            type="checkbox"
                            class="form-check-input"
                            v-model="item.keepBetExceed"
                          />
                          <label
                            class="form-check-label"
                            :for="`inputKeepShare${item.id}`"
                            >เก็บของที่เกิน</label
                          >
                        </div>
                      </td>
                    </template>
                    <template #save="{ item }">
                      <td>
                        <button
                          class="btn btn-primary"
                          @click="saveShare(item)"
                        >
                          บันทึก
                        </button>
                      </td>
                    </template>
                  </CDataTable>
                </div>
              </b-card-text>
            </b-tab> -->
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breacrumbItems:
        this.$route.name == "SettingMember"
          ? ["ตั้งค่า", "สมาชิก"]
          : ["จัดการสมาชิก", "ข้อมูลทั่วไป/เก็บของ"],
      members: [],
      giveShares: [],
      isLoading: true,
      isNotFound: false,
      fieldsNormal: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "levelTH",
          label: "ระดับ",
        },
        {
          key: "nameEdit",
          label: "ชื่อ",
          filter: false,
        },
        {
          key: "phoneNoEdit",
          label: "โทร",
          filter: false,
        },
        {
          key: "statusEdit",
          label: "สถานะ",
          filter: false,
        },
        {
          key: "createdAt",
          label: "สมัครเมื่อ",
          filter: false,
        },
        {
          key: "save",
          label: "บันทึก",
          filter: false,
        },
        {
          key: "creditBalance",
          label: "จำนวนเงิน",
          filter: false,
        },
      ],
      fieldsPassword: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "name",
          label: "ชื่อ",
        },
        {
          key: "levelTH",
          label: "ระดับ",
        },
        {
          key: "passwordEdit",
          label: "รหัสผ่าน",
          filter: false,
        },
        {
          key: "save",
          label: "บันทึก",
          filter: false,
        },
      ],
      fieldsShare: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "name",
          label: "ชื่อ",
        },
        {
          key: "levelTH",
          label: "ระดับ",
        },
        {
          key: "sharePercentKey",
          label: "แบ่งหุ้น",
          filter: false,
        },
        {
          key: "keepSharePercentKey",
          label: "เก็บของ",
          filter: false,
        },
        {
          key: "keepBetExceedKey",
          label: "เก็บของเกิน",
          filter: false,
        },
        {
          key: "save",
          label: "บันทึก",
          filter: false,
        },
      ],
      itemsPerPage: 50,
      tableLoading: true,
      timer: null,
      user: {},
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    makeSharePercentItem(sharePercent) {
      let sharePercents = []
      for (
        let i = parseInt(this.user.sharePercent);
        i >= sharePercent;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      let keepShare =
        item.level == "MEMBER" ? this.user.sharePercent : item.keepSharePercent
      for (let i = keepShare; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }
      return keepShares
    },
    async saveData(item) {
      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-profile",
          data: {
            id: item.id,
            name: item.name,
            phoneNo: item.phoneNo,
            status: item.status,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
    async savePassword(item) {
      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-password",
          data: {
            id: item.id,
            password: item.password,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async saveShare(item) {
      this.swal.processing()
      try {
        this.axios({
          method: "post",
          url: "users/change-share",
          data: {
            id: item.id,
            sharePercent: item.sharePercent,
            keepSharePercent: item.keepSharePercent,
            keepBetExceed: item.keepBetExceed,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async loadData() {
      this.tableLoading = true

      try {
        let res = await this.axios({
          mehotd: "get",
          url: "users/edit-member-data",
          params: {
            userId: this.$route.params.userId,
          },
        })

        let data = res.data.data

        if (data.members.length == 0) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        data.members.forEach((item) => {
          item._classes = item.status == "INACTIVE" ? "table-danger" : ""
          item._cellClasses = {
            creditBalance: this.util.getClassAmount(item.creditBalance),
          }
          item.creditBalance = this.util.formatMoney(item.creditBalance)
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        })

        this.members = data.members
        this.user = data.user

        this.tableLoading = false
        this.isLoading = false
      } catch (e) {
        console.log(e)

        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.td-input {
  min-width: 150px;
}

.td-input-status {
  min-width: 75px;
}
</style>