<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ตั้งค่าระบบ']" />

    <div class="card border-primary mb-2">
      <h5 class="card-header border-primary bg-primary text-white">
        ข้อมูลการแทง
      </h5>
      <div class="card-body">
        <loading v-show="isCategoryLoading" />
        <ValidationObserver
          ref="observerLottoCategories"
          tag="form"
          @submit.prevent="editLottoCategories"
          v-show="!isCategoryLoading"
          novalidate
        >
          <div class="table-responsive">
            <table
              class="table table-bordered table-hover table-dark text-center"
            >
              <thead>
                <tr>
                  <th rowspan="2" class="align-middle">ประเภท</th>
                </tr>
                <tr>
                  <th>ขั้นต่ำ</th>
                  <th>สูงสุดต่อบิล</th>
                  <th>สูงสุดต่อเลขต่อคน</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categories" :key="item.id">
                  <th scope="row">{{ item.name }}</th>
                  <td>
                    <ValidationProvider
                      :rules="`required|max_value:999999|min_value:1|numeric`"
                      v-slot="v"
                    >
                      <input
                        type="number"
                        class="form-control min-width-5"
                        @keypress="$root.onlyNumber"
                        v-model.number="item.buyMin"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider
                      :rules="`required|max_value:999999|min_value:1|numeric`"
                      v-slot="v"
                    >
                      <input
                        type="number"
                        class="form-control min-width-5"
                        @keypress="$root.onlyNumber"
                        v-model.number="item.buyMaxPerBill"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider
                      :rules="`required|max_value:999999|min_value:1|numeric`"
                      v-slot="v"
                    >
                      <input
                        type="number"
                        class="form-control min-width-5"
                        @keypress="$root.onlyNumber"
                        v-model.number="item.buyMaxPerNumber"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button class="btn btn-primary">บันทึก</button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
th.diagonal-left {
  padding: 0px;
  margin: 0;

  div {
    position: relative;
    height: 50px;
    width: 100px;
    top: 0;
    left: 0;
  }

  .line {
    width: 112px;
    height: 47px;
    border-bottom: 1px solid #454d55;
    transform: translateY(-20px) translateX(5px) rotate(27deg);
    position: absolute;
    z-index: 1;
  }

  .bottom {
    position: absolute;
    bottom: 1px;
    left: 1px;
    white-space: nowrap;
    margin: 0 0 4px 8px;
  }

  .top {
    position: absolute;
    top: 1px;
    right: 1px;
    white-space: nowrap;
    margin: 4px 8px 0 0;
  }
}
</style>

<script>
import SettingProvider from "@/resources/setting_provider"
const settingService = new SettingProvider()

export default {
  data() {
    return {
      lottoCategoryForm: {
        huayCategories: [],
      },
      lottoGroupPayForm: {
        huayGroupPays: [],
      },
    }
  },
  mounted() {
    this.getLottoCategoryList()
    this.getLottoGroupList()
    this.getPayRateList()
    this.getLottoGroupPayList()
  },
  computed: {
    isCategoryLoading: function () {
      return this.$store.state.lotto.isLottoCategoryLoading
    },
    categories: function () {
      return this.$store.state.lotto.lottoCategories
    },
    lottoGroups: function () {
      return this.$store.state.lotto.lottoGroups
    },
    isLottoGroupPayLoading: function () {
      return (
        this.$store.state.lotto.isLottoGrouLoading ||
        this.$store.state.setting.isPayRateLoading ||
        this.$store.state.lotto.isLottoGroupPayLoading
      )
    },
    payRates: function () {
      return this.$store.state.setting.payRates
    },
    lottoGroupPays: function () {
      return this.$store.state.lotto.lottoGroupPays
    },
  },
  watch: {
    categories: function (newVal) {
      this.lottoCategoryForm.huayCategories = newVal
    },
    lottoGroups: function () {
      this.initLottoGroupPayForm()
    },
    payRates: function () {
      this.initLottoGroupPayForm()
    },
    lottoGroupPays: function () {
      this.initLottoGroupPayForm()
    },
  },
  methods: {
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList", {}, { root: true })
    },
    getLottoGroupList() {
      this.$store.dispatch("lotto/getAllLottoGroupList", {}, { root: true })
    },
    getPayRateList() {
      this.$store.dispatch("setting/getPayRateList", {}, { root: true })
    },
    getLottoGroupPayList() {
      this.$store.dispatch("lotto/getLottoGroupPayList", {}, { root: true })
    },
    isHuayGroupPayOpen(huayPayId, huayGroupId) {
      var huayGroupPay = this.lottoGroupPayForm.huayGroupPays.find(
        (ele) => ele.huayPayId == huayPayId && ele.huayGroupId == huayGroupId
      )
      return huayGroupPay ? huayGroupPay.status == "OPEN" : false
    },
    toggleHuayGroupPayStatus(huayPayId, huayGroupId) {
      var huayGroupPay = this.lottoGroupPayForm.huayGroupPays.find(
        (ele) => ele.huayPayId == huayPayId && ele.huayGroupId == huayGroupId
      )
      if (huayGroupPay == null) {
        return
      }
      huayGroupPay.status = huayGroupPay.status == "OPEN" ? "CLOSE" : "OPEN"
    },
    initLottoGroupPayForm() {
      var lottoGroupPays = []
      this.payRates.forEach((ele) => {
        let lottoGroupPay = {
          id: null,
          huayGroupId: null,
          huayPayId: ele.huayPay.id,
          status: "CLOSE",
        }
        this.lottoGroups.forEach((ele2) => {
          let lottoGroupPayOld = this.lottoGroupPays.find(
            (ele3) =>
              ele3.huayGroupId == ele2.id && ele3.huayPayId == ele.huayPay.id
          )
          let lottoGroupPay2 = Object.assign({}, lottoGroupPay)
          lottoGroupPay2.huayGroupId = ele2.id
          if (lottoGroupPayOld) {
            lottoGroupPay2.id = lottoGroupPayOld.id
            lottoGroupPay2.status = lottoGroupPayOld.status
          }
          lottoGroupPays.push(lottoGroupPay2)
        })
      })
      this.lottoGroupPayForm.huayGroupPays = lottoGroupPays
    },
    async editLottoCategories() {
      let observer = this.$refs.observerLottoCategories
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }
      this.swal.processing()
      const res = await settingService.editLottoCategories(
        this.lottoCategoryForm
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess("แก้ไขข้อมูลการแทงสำเร็จ")
      }
    },
    async editLottoGroupPays() {
      let observer = this.$refs.observerLottoGroupPays
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = await settingService.editLottoGroupPays(
        this.lottoGroupPayForm
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess("แก้ไขอัตราจ่าย กลุ่มหวย สำเร็จ")
      }
    },
  },
}
</script>