<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['จัดการสมาชิก', 'รายการเก็บของตามสมาชิก']"
    />
    <h4>
      รายการเก็บของตามสมาชิก
      <span class="text-normal"
        >&gt;&gt;
        <a href="" @click="getTakeList">{{ currentUser.username }}</a>
        &gt;&gt;</span
      >
    </h4>
    <round-detail />
    <loading v-show="isLoading" />

    <div class="table-responsive" v-show="!isLoading">
      <table
        class="table table-dark text-center w-100 table-hover table-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>ชื่อผู้ใช้</th>
            <th>ระดับ</th>
            <th>ได้รับ</th>
            <th v-for="cate in categories" :key="cate.id">
              {{ cate.name }}
            </th>
            <th>ดูของ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td class="text-right">{{ user.id }}</td>
            <td>
              <a
                href=""
                v-if="user.numberOfMember"
                @click="setCurrentUser(user)"
                >{{ user.username }}</a
              >
              <span v-if="user.numberOfMember == 0">{{ user.username }}</span>
            </td>
            <td>{{ user.levelTH }}</td>
            <td>{{ util.formatMoney(user.sharePercent) }}%</td>
            <td v-for="cate in categories" :key="cate.id">
              {{ util.formatMoney(getTotalReceiveAmount(user.id, cate.id)) }}
            </td>
            <td>
              <a href="" @click="goToItemOverallPage(user)">ดูของ</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserProvider from "@/resources/user_provider"

const userService = new UserProvider()
export default {
  data() {
    return {
      userId: null,
      currentUserId: null,
      currentUser: {},
      categories: [],
      users: [],
      totalReceiveAmounts: [],
      isLoading: false,
    }
  },
  computed: {
    user: function () {
      return this.$store.state.auth.user.userData
    },
    roundId: function () {
      return this.$store.state.auth.roundId
    },
  },
  watch: {
    user: function () {
      if (!this.userId) {
        this.currentUserId = this.user.id
      }
    },
    currentUserId: function () {
      this.getTakeList()
    },
  },
  methods: {
    setCurrentUser(user) {
      this.$router.push(`/members/take-list/${user.id}`)
    },
    async getTakeList() {
      if (!this.currentUserId) {
        return
      }
      this.isLoading = true
      const res = await userService.getTakeList(
        this.currentUserId,
        this.roundId
      )
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.currentUser = res.result.userRefData
        this.users = res.result.users
        this.categories = res.result.huayCategories
        this.items = res.result.items
      }
    },
    getTotalReceiveAmount(userId, huayCategoryId) {
      return (
        this.totalReceiveAmounts.find(
          (ele) => ele.userId == userId && ele.huayCategoryId == huayCategoryId
        )?.totalReceiveAmount || 0
      )
    },
    goToItemOverallPage(user) {
      this.$router.push(`/items/overall/${user.id}`)
    },
  },
  mounted() {
    this.userId = this.$router.currentRoute.params.userId
    if (this.userId) {
      this.currentUserId = this.userId
    } else {
      // this.currentUser = this.user;
      this.currentUserId = this.user.id
    }
  },
}
</script>
