<template>
  <nav class="bg-nav-top d-flex justify-content-between py-2 pr-2">
    <router-link class="d-flex ml-5" to="/welcome">
      <div class="border-right pr-2" style="border-color: yellow">
        <img src="~@/assets/images/logo-main.png" height="40" />
      </div>
      <span class="my-auto h4 ml-2 text-white" style="letter-spacing: 5px"
        >AGENT</span
      >
    </router-link>

    <div class="d-flex">
      <span class="h5 my-auto text-black" style="width: 340px">{{
        currentTime
      }}</span>

      <button
        class="btn btn-primary rounded-0"
        @click="reload"
        :disabled="disabledReload"
      >
        <i class="fas fa-redo"></i>
      </button>

      <b-dropdown :text="this.$store.state.auth.typeName">
        <b-dropdown-group
          :header="item.name"
          v-for="(item, index) in huayGroups"
          :key="index"
        >
          <b-dropdown-item-button
            v-for="(huayType, index2) in filterHuayTypes(item)"
            :key="index2"
            :active="typeActive.id == huayType.id"
            @click="setType(huayType, true)"
          >
            {{ huayType.name }}
          </b-dropdown-item-button>
        </b-dropdown-group>
      </b-dropdown>
      <input
        class="form-control rounded-0"
        type="text"
        style="max-width: 150px; height: 43px"
        @keypress="$root.onlyNumber"
        v-model.number="$store.state.auth.roundId"
        v-if="typeActive.multiRoundPerDay"
        v-on:change="setRoundId"
        v-on:blur="reload"
        placeholder="#ID"
      />
      <b-dropdown
        v-if="!typeActive.multiRoundPerDay"
        variant="success"
        :text="this.$store.state.auth.roundName"
      >
        <b-dropdown-item
          v-for="(item, index) in filterHuayRounds()"
          :key="index"
          :active="roundActive.id == item.id"
          @click="setRound(item, true)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </nav>
</template>

<style>
.dropdown-header {
  background-color: #28681b;
  color: white !important;
}

.dropdown-toggle {
  border-radius: 0 !important;
}

.dropdown-menu.show {
  border-radius: 0 !important;
}

.dropdown-item {
  font-size: 0.75rem !important;
}

.bg-nav-top {
  background: rgb(225, 254, 255);
  background: linear-gradient(
    267deg,
    rgba(225, 254, 255, 1) 15%,
    rgba(65, 176, 196, 1) 70%
  );
}

a.navbar-brand {
  color: white;
  font-size: 1.4rem;
}

.navbar-toggler-icon {
  color: white;
}

.navbar {
  padding: 0;
}
</style>

<script>
export default {
  name: "Header",
  data() {
    return {
      currentTime: "",
      roundActive: {},
      typeActive: {},
      huayGroups: [],
      huayTypes: [],
      huayRounds: [],
      disabledReload: false,
      timeout: null
    }
  },
  methods: {
    setRoundId() {
      this.$store.state.auth.roundName = "#" + this.$store.state.auth.roundId
    },
    reload() {
      this.disabledReload = true

      this.timeout = setTimeout(() => {
        this.disabledReload = false
      }, 3000)

      this.$store.dispatch("auth/forceReload")
    },
    filterHuayRounds() {
      return this.huayRounds.filter(
        (item) => item.huayTypeId == this.typeActive.id
      )
    },
    setRound(item, reload) {
      this.roundActive = item
      this.$store.state.auth.roundId = item.id
      this.$store.state.auth.roundName = item.name
      this.$store.state.auth.roundDate = this.$date(item.closeDate).format(
        "DD-MM-YYYY"
      )

      if (reload) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    setType(item, reload) {
      this.typeActive = item
      this.$store.state.auth.typeId = item.id
      this.$store.state.auth.typeName = item.name
      let firstRound = this.huayRounds.find(
        (item) => item.huayTypeId == this.typeActive.id
      )
      let huayRoundBetween = this.huayRounds.find(
        (item) =>
          item.huayTypeId == this.typeActive.id &&
          this.$date().isBetween(
            this.$date(item.openTime),
            this.$date(item.closeTime)
          )
      )
      let huayRound = this.huayRounds.find(
        (item) =>
          item.huayTypeId == this.typeActive.id &&
          this.$date().format("YYYY-MM-DD") ==
            this.$date(item.closeTime).format("YYYY-MM-DD")
      )
      if (huayRoundBetween) {
        this.setRound(huayRoundBetween, reload)
      } else if (huayRound) {
        this.setRound(huayRound, reload)
      } else if (firstRound) {
        this.setRound(firstRound, reload)
      } else {
        this.setRound({}, false)
      }
    },
    filterHuayTypes(item) {
      return this.huayTypes.filter((item2) => item2.huayGroupId == item.id)
    },
    makeCurrentTime() {
      let date = this.$date()
      this.currentTime = `วันที่ ${date.format("DD")} ${date.format("MMMM")} ${
        parseInt(date.format("YYYY")) + 543
      } ${date.format("HH:mm:ss")}`
    }
  },
  beforeCreate: function () {
    document.body.className = ""
  },
  async created() {
    this.makeCurrentTime()

    setInterval(() => {
      this.makeCurrentTime()
    }, 1000)

    this.swal.processing()

    try {
      let res = await this.axios({
        method: "get",
        url: "/huay/round/header"
      })

      let data = res.data.data
      this.huayGroups = data.huayGroups
      this.huayTypes = data.huayTypes
      this.huayRounds = data.huayRounds

      this.setType(this.huayTypes[0], false)

      let huayRound = this.huayRounds.find(
        (item) =>
          this.$date(item.closeDate).format("DD-MM-YYYY") ==
            this.$date().format("DD-MM-YYYY") &&
          item.huayTypeId == this.huayTypes[0].id
      )

      if (!huayRound) {
        huayRound = this.huayRounds.find(
          (item) => item.huayTypeId == this.huayTypes[0].id
        )
      }

      this.setRound(huayRound, false)

      this.swal.close()
    } catch (e) {
      console.log(e)
      this.swal.swalError()
    }
  }
}
</script>
