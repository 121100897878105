<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', 'ตั้งค่าการรับของ']" />
    <round-detail />
    <div class="alert alert-danger">
      <h5>
        <i class="fas fa-exclamation-triangle"></i> เรียนท่านที่ต้องการ หุ้น
        หรือ เก็บของ
      </h5>
      <span
        >กรุณาตั้งจำนวนการรับของต่อตัวในหน้า
        <router-link to="/items/limit-settings">ตั้งค่าการรับของ</router-link>
        เพื่อกำหนดว่าท่านจะรับของสูงสุดต่อตัวตัวละเท่าไร หากไม่มีการตั้ง
        ระบบจะถือว่าท่านไม่ต้องการสู้</span
      >
    </div>
    <div class="mb-3">
      <h4>
        <i class="fa fa-angle-right text-primary" />
        ตั้งค่าการรับของเป็นรายตัว (เฉพาะงวด)
      </h4>
      ใส่จำนวนที่ต้องการรับต่อหมายเลข
    </div>
    <loading v-show="isLoading" />
    <div class="row" v-show="!isLoading">
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 m-0"
        v-for="(category, indexCat) in categorys"
        :key="indexCat"
      >
        <table
          class="
            table table-sm table-bordered table-hover table-dark
            text-center
          "
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  @change="toggleChecked(category)"
                  v-model="category.checked"
                />
              </th>
              <th>{{ category.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex flex-column">
                  <input
                    :id="category.id + 'reverse'"
                    type="checkbox"
                    v-model="category.reverse"
                    class="mx-auto"
                  />
                  <label :for="category.id + 'reverse'">กลับ</label>
                </div>
              </td>
              <td class="d-flex">
                <input
                  type="text"
                  class="form-control"
                  style="min-width: 53px"
                  @keypress="$root.onlyNumber"
                  v-model="category.number"
                  placeholder="เลข"
                />
                <span class="m-1 my-auto">=</span>
                <input
                  type="text"
                  class="form-control mr-1"
                  style="min-width: 53px"
                  @keypress="$root.onlyNumber"
                  v-model="category.amount"
                  @change="setAmount(category)"
                  placeholder="จำนวนที่จะรับ"
                />
                <button class="btn btn-primary" @click="create(category)">
                  เพิ่ม
                </button>
              </td>
            </tr>
            <tr v-if="filterBetLimitByNumbers(category.id).length == 0">
              <td colspan="2">ไม่มีข้อมูล</td>
            </tr>
            <tr
              v-for="(item, index) in filterBetLimitByNumbers(category.id)"
              :key="index"
            >
              <td><input type="checkbox" v-model="item.checked" /></td>
              <td>
                {{ item.number }} = {{ item.amount
                }}{{ item.percent ? "%" : "" }}
              </td>
            </tr>
            <tr v-if="filterBetLimitByNumbers(category.id).length != 0">
              <td colspan="2">
                <div class="d-flex justify-content-center">
                  <input
                    style="max-width: 150px"
                    type="text"
                    class="form-control mr-1"
                    v-model="category.amountAll"
                    placeholder="จำนวนที่จะรับ"
                  />
                  <button
                    class="btn btn-primary mr-1"
                    @click="update(category)"
                  >
                    แก้ไข
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="deleteBetLimitByNumbers"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryList: [],
      betLimitPerNumber: {},
      betLimitByNumbers: [],
      isLoading: true,
    }
  },
  computed: {
    categorys() {
      return this.categoryList
    },
    roundId: function () {
      return this.$store.state.auth.roundId
    },
    typeId: function () {
      return this.$store.state.auth.typeId
    },
  },
  watch: {
    roundId() {
      this.getDetail()
    },
  },
  methods: {
    setAmount(category) {
      let item = this.$store.state.item.settingCategorys.find(
        (item) => item.id == category.id
      )
      if (item) {
        item.amount = category.amount
      } else {
        this.$store.state.item.settingCategorys.push(category)
      }
    },
    toggleChecked(category) {
      this.betLimitByNumbers.forEach((item) => {
        if (item.huayCategoryId == category.id) {
          item.checked = category.checked
        }
      })
    },
    filterBetLimitByNumbers(categoryId) {
      return this.betLimitByNumbers.filter(
        (item) => item.huayCategoryId == categoryId
      )
    },
    deleteBetLimitByNumbers() {
      let betLimitByNumberIds = []
      this.betLimitByNumbers.forEach((item) => {
        if (item.checked == true) {
          betLimitByNumberIds.push(item.id)
        }
      })
      if (betLimitByNumberIds.length == 0) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "delete",
        url: "bet/limit-by-number",
        data: {
          betLimitByNumberIds: betLimitByNumberIds,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "แก้ไขสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    update(category) {
      let amount = category.amountAll
      if (!amount) {
        return
      }

      let isPercent = /^\d+(\.\d+)?%$/.test(amount)
      amount = isPercent ? parseInt(amount.slice(0, -1)) : parseInt(amount)
      if ((isPercent && amount > 100) || amount < 0) {
        this.$swal({
          icon: "error",
          title: "หมายเลขไม่ถูกต้อง",
        })
        return
      }

      let betLimitByNumberIds = []
      this.betLimitByNumbers.forEach((item) => {
        if (item.checked == true) {
          betLimitByNumberIds.push(item.id)
        }
      })
      if (betLimitByNumberIds.length == 0) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "patch",
        url: "bet/limit-by-number",
        data: {
          betLimitByNumberIds: betLimitByNumberIds,
          amount: amount,
          percent: isPercent,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "แก้ไขสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    create(category) {
      let isPercent = /^\d+(\.\d+)?%$/.test(category.amount)
      let amount = isPercent
        ? parseInt(category.amount.slice(0, -1))
        : parseInt(category.amount)
      if (
        category.numberLength != category.number.toString().length ||
        (isPercent && amount > 100) ||
        amount < 0
      ) {
        this.$swal({
          icon: "error",
          title: "หมายเลขไม่ถูกต้อง",
        })
        return
      }

      let res = this.betLimitByNumbers.find(
        (item) =>
          item.huayCategoryId == category.id && item.number == category.number
      )
      if (res) {
        this.swal.swalError("หมายเลขซ้ำ")
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "bet/limit-by-number/create",
        data: {
          huayRoundId: this.roundId,
          huayTypeId: this.typeId,
          huayCategoryId: category.id,
          percent: isPercent,
          amount: amount,
          number: category.number,
          reverse: category.reverse,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "ตั้งค่ารับของสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    async getDetail() {
      try {
        let p1 = this.axios({
          method: "post",
          url: "bet/limit-by-number",
          data: {
            huayTypeId: this.typeId,
            huayRoundId: this.roundId,
          },
        })
        let p2 = this.axios.get("huay/category/list")

        let [res1, res2] = await Promise.all([p1, p2])

        let data = res1.data.data
        if (data.betLimitByNumbers) {
          data.betLimitByNumbers.forEach((item) => (item.checked = false))
          data.betLimitByNumbers.sort(function (a, b) {
            return a.number < b.number ? 1 : -1
          })
          this.betLimitByNumbers = data.betLimitByNumbers
        }

        let catList = res2.data.data.list
        catList.forEach((item) => {
          item.checked = false
          let category = this.$store.state.item.settingCategorys.find(
            (item2) => item2.id == item.id
          )
          if (category) {
            item.amount = category.amount
          }
        })
        this.categoryList = catList

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  async mounted() {
    this.getDetail()
  },
}
</script>
