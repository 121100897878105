<template>
  <div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <bread-cum v-bind:breadcrumbItems="['การเงิน', 'เติมเงิน']" />
      <div class="alert alert-info" id="info">
        การ "เคลียยอด "คือการปรับ "เงินคงเหลือ" ให้มีค่าเท่ากับ "เครดิต"<br />
        ถ้า "ส่วนต่าง" ของสมาชิกเป็น
        <span class="positive">บวก</span>
        การกดปุ่มนี้จะเป็นการถอนเงินจากสมาชิก<br />
        ถ้า "ส่วนต่าง" ของสมาชิกเป็น
        <span class="negative">ลบ</span>
        การกดปุ่มนี้จะเป็นการฝากเงินเข้าสมาชิก<br />
        <br />
        <b
          >ถ้าหาก "เครดิต" ของสมาชิกเป็น 0 เมื่อกดเคลียยอด จะทำให้ "เงินคงเหลือ"
          ของสมาชิกเป็น 0</b
        >
      </div>
      <ValidationObserver ref="observerEditCredit" v-if="showEditCredit">
        <div class="card border-primary mb-3">
          <h5 class="card-header border-primary bg-primary">แก้ไขเครดิต</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เครดิต:</div>
              <div class="col-md-2 p-md-0">
                <span>{{
                  util.formatMoney(memberEditCredit.creditMaximum)
                }}</span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนเครดิต:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="จำนวนเครดิต"
                    v-model="memberEditCredit.creditMaximumEdit"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" @click="saveCreditMaximum">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showEditCredit = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver ref="observerCreditDeposit" v-if="showDeposit">
        <div class="card border-primary mb-3">
          <h5 class="card-header border-primary bg-primary">ฝากเงิน</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือ:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(memberTransfer.creditBalance)">
                  {{ memberTransfer.creditBalance }}
                </span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือตัวเรา:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(user.creditBalance)">
                  {{ util.formatMoney(user.creditBalance) }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนฝาก:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider
                  :rules="`required|min_value:0|max_value:${user.creditBalance}`"
                  v-slot="v"
                >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="จำนวนฝาก"
                    v-model="memberTransfer.creditDeposit"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" @click="depositCredit">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showDeposit = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver ref="observerCreditWithdraw" v-if="showWithdraw">
        <div class="card border-primary mb-3" v-if="showWithdraw">
          <h5 class="card-header border-primary bg-primary">ถอนเงิน</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือ:</div>
              <div class="col-md-2 p-md-0">
                <span class="positive">{{ memberTransfer.creditBalance }}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือตัวเรา:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(user.creditBalance)">{{
                  util.formatMoney(user.creditBalance)
                }}</span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนถอน:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider
                  :rules="`required|min_value:0|max_value:${memberTransfer.creditBalance}`"
                  v-slot="v"
                >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="จำนวนถอน"
                    v-model="memberTransfer.creditWithdraw"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" @click="withdrawCredit">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showWithdraw = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="table-responsive" v-if="members">
        <table
          class="table table-bordered table-hover table-dark text-center fs-075"
        >
          <thead>
            <tr>
              <th>ชื่อผู้ใช้</th>
              <th>ระดับ</th>
              <th>ชื่อ</th>
              <th>เครดิต</th>
              <th>แก้ไขเครดิต</th>
              <th>เงินคงเหลือ</th>
              <th>
                <span class="positive">+ฝาก</span>/<span class="negative"
                  >-ถอน</span
                >
              </th>
              <th>
                <input
                  type="checkbox"
                  v-model="checkAllClearCredit"
                  @change="toggleCheckAllClearCredit"
                />
              </th>
              <th>ส่วนต่าง</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(member, index) in members"
              :key="index"
              :class="{ 'table-danger': member.status == 'INACTIVE' }"
            >
              <td>{{ member.username }}</td>
              <td>{{ member.levelTH }}</td>
              <td>{{ member.name }}</td>
              <td>{{ util.formatMoney(member.creditMaximum) }}</td>
              <td>
                <button
                  class="btn btn-sm btn-primary"
                  @click="setShowEditCredit(member)"
                >
                  แก้ไข
                </button>
              </td>
              <td :class="getClassCredit(member.creditBalance)">
                {{ util.formatMoney(member.creditBalance) }}
              </td>
              <td class="d-flex justify-content-center">
                <button
                  class="btn btn-sm btn-success mr-1"
                  @click="setShowDeposit(member)"
                >
                  ฝาก
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  @click="setShowWithdraw(member)"
                >
                  ถอน
                </button>
              </td>
              <td>
                <input type="checkbox" v-model="member.checkClearCredit" />
              </td>
              <td :class="getClassCredit(member.creditDiff)">
                {{ util.formatMoney(member.creditDiff) }}
              </td>
            </tr>
            <tr>
              <td colspan="5">รวม</td>
              <td :class="getClassCredit(sumCreditBalance)">
                {{ util.formatMoney(sumCreditBalance) }}
              </td>
              <td colspan="2">
                <div class="d-flex flex-column">
                  <button class="btn btn-primary mb-1" @click="clearCredit">
                    เคลียยอด
                  </button>
                  <span> ถ้า "เครดิต" สมาชิกเป็น 0 </span>
                  <span> เมื่อกดแล้ว "เงินคงเหลือ" สมาชิกจะเป็น 0 </span>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!members" class="text-center mt-3 h1">ไม่พบสมาชิก</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEditCredit: false,
      memberEditCredit: {},
      memberTransfer: {},
      showDeposit: false,
      showWithdraw: false,
      isLoading: true,
      members: [],
      sumCreditBalance: 0,
      user: {},
      checkAllClearCredit: false,
    }
  },
  methods: {
    toggleCheckAllClearCredit() {
      this.members.forEach((item) => {
        item.checkClearCredit = this.checkAllClearCredit
      })
    },
    getClassCredit(a) {
      if (parseFloat(a) == parseFloat(0)) {
        return ""
      }
      if (parseFloat(a) > parseFloat(0)) {
        return "positive"
      }
      return "negative"
    },
    setShowEditCredit(member) {
      this.showDeposit = false
      this.showWithdraw = false
      this.showEditCredit = true
      if (this.showEditCredit) {
        this.memberEditCredit = member
        this.$el.querySelector("#info").scrollIntoView({
          block: "center",
        })
      }
    },
    setShowDeposit(member) {
      this.showEditCredit = false
      this.showWithdraw = false
      this.showDeposit = true
      if (this.showDeposit) {
        this.memberTransfer = member
        this.$el.querySelector("#info").scrollIntoView({
          block: "center",
        })
      }
    },
    setShowWithdraw(member) {
      this.showDeposit = false
      this.showEditCredit = false
      this.showWithdraw = true
      if (this.showWithdraw) {
        this.memberTransfer = member
        this.$el.querySelector("#info").scrollIntoView({
          block: "center",
        })
      }
    },
    async saveCreditMaximum() {
      let observer = this.$refs.observerEditCredit
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "credit/set-credit-maximum",
          data: this.memberEditCredit,
        })

        await this.$swal({
          icon: "success",
          text: "แก้ไขเครดิตสำเร็จ",
        })

        await this.initData()
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async withdrawCredit() {
      let observer = this.$refs.observerCreditWithdraw
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/transfer",
          data: {
            userId: this.memberTransfer.id,
            creditWithdraw: this.memberTransfer.creditWithdraw,
          },
        })

        await this.$swal({
          icon: "success",
          text: "ถอนเงินสำเร็จ",
        })

        await this.initData()
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async depositCredit() {
      let observer = this.$refs.observerCreditDeposit
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/transfer",
          data: {
            userId: this.memberTransfer.id,
            creditDeposit: this.memberTransfer.creditDeposit,
          },
        })

        await this.$swal({
          icon: "success",
          text: "ฝากเงินสำเร็จ",
        })

        await this.initData()
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async clearCredit() {
      let checked = this.members.find((item) => item.checkClearCredit)
      if (!checked) {
        return
      }

      let transferCredits = []
      this.members.forEach((item) => {
        if (item.checkClearCredit) {
          let creditDeposit, creditWithdraw
          if (parseFloat(item.creditDiff) > parseFloat(0)) {
            creditWithdraw = item.creditDiff
          } else if (parseFloat(item.creditDiff) < parseFloat(0)) {
            creditDeposit = parseFloat(item.creditDiff) * -1
          } else {
            return
          }

          transferCredits.push({
            userId: item.id,
            creditDeposit: creditDeposit,
            creditWithdraw: creditWithdraw,
          })
        }
      })

      if (transferCredits.length == 0) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/clear",
          data: {
            transferCredits: transferCredits,
          },
        })

        await this.$swal({
          icon: "success",
          text: "เคลียยอดสำเร็จ",
        })

        await this.initData()
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async initData() {
      try {
        let res = await this.axios.get("users/credit-transfer-data")

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        this.sumCreditBalance = parseFloat(0.0)
        this.showEditCredit = false
        this.showWithdraw = false
        this.showDeposit = false
        this.checkAllClearCredit = false

        let data = res.data.data
        data.members?.forEach((item) => {
          item.creditMaximum = parseFloat(item.creditMaximum).toFixed(2)
          item.creditBalance = parseFloat(item.creditBalance).toFixed(2)
          item.creditDeposit = null
          item.creditWithdraw = null
          item.creditMaximumEdit = null
          item.checkClearCredit = false

          item.creditDiff = (
            parseFloat(item.creditBalance) - parseFloat(item.creditMaximum)
          ).toFixed(2)

          this.sumCreditBalance =
            parseFloat(this.sumCreditBalance) + parseFloat(item.creditBalance)
        })

        this.$store.state.auth.user.userData = data.user
        this.user = data.user
        this.sumCreditBalance = this.sumCreditBalance.toFixed(2)
        this.members = data.members
        this.isLoading = false
        this.$store.state.auth.user.userData = data.user
      } catch (e) {
        console.log(encodeURIComponent)
        this.swal.swalError()
      }
    },
  },
  async mounted() {
    await this.initData()
  },
}
</script>