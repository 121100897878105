<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', pageName]" />
    <user-nav :name="pageName"></user-nav>
    <round-detail />
    <b-card no-body>
      <b-tabs card>
        <b-tab
          title="แยกตามสมาชิก"
          @click="getByMemberData()"
          :active="!$route.query.page || $route.query.page == 'by-member'"
        >
          <b-card-text>
            <CDataTable
              :loading="tableLoadingByMember"
              :items="betResultByMembers"
              :fields="getFields()"
              :noItemsView="{
                noResults: 'ไม่พบข้อมูล',
                noItems: 'ไม่พบข้อมูล',
              }"
              :header="false"
              size="sm"
              dark
              border
              striped
              addTableClasses="table-white report"
            >
              <template v-slot:thead-top>
                <tr>
                  <th rowspan="2">
                    <div class="d-flex flex-column">
                      <span>ชื่อผู้ใช้</span>
                      <div class="d-flex justify-content-center">
                        <input
                          id="showMemberName"
                          type="checkbox"
                          class="cursor-pointer mr-1 my-auto"
                          v-model="showMemberName"
                        />
                        <label for="showMemberName" class="my-auto"
                          >แสดงชื่อ</label
                        >
                      </div>
                    </div>
                  </th>
                  <th rowspan="2">ระดับชั้น</th>
                  <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
                  <th :colspan="colspan" class="br-white">สมาชิก</th>
                  <th :colspan="colspan" class="br-white">
                    {{ user.levelTH }} [{{ user.username }}]
                  </th>
                  <th :colspan="colspan">บริษัท</th>
                </tr>
                <tr>
                  <!-- member -->
                  <th>ยอดส่งออก</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>คอม</th>
                  <th class="br-white">รวม</th>

                  <!-- me -->
                  <th>ถือหุ้น</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>คอม</th>
                  <th class="br-white">รวม</th>

                  <!-- company -->
                  <th>ถือหุ้น</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>คอม</th>
                  <th>รวม</th>
                </tr>
              </template>
              <template #username="{ item }">
                <td class="text-left">
                  <router-link
                    :to="{
                      name: getNameRouterLink(item.level),
                      params: { userId: item.userId },
                    }"
                    >{{ item.username }}</router-link
                  >
                  <span class="ml-1" v-if="showMemberName"
                    >({{ item.name }})</span
                  >
                </td>
              </template>
              <template v-slot:footer>
                <tfoot>
                  <tr>
                    <td colspan="2" class="text-left">รวม:</td>

                    <!-- member -->
                    <td class="bg-blue br-white">
                      {{ util.formatMoney(sumBetResultByMember.buyAmount) }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: sumBetResultByMember.member.buyAmount > 0,
                          negative: sumBetResultByMember.member.buyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.member.buyAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByMember.member.winAmount > 0,
                          negative: sumBetResultByMember.member.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.member.winAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByMember.member.commissionAmount > 0,
                          negative:
                            sumBetResultByMember.member.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.member.commissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByMember.member.sumAmount > 0,
                          negative: sumBetResultByMember.member.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.member.sumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive: sumBetResultByMember.me.shareAmount > 0,
                          negative: sumBetResultByMember.me.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(sumBetResultByMember.me.shareAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByMember.me.winAmount > 0,
                          negative: sumBetResultByMember.me.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(sumBetResultByMember.me.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByMember.me.commissionAmount > 0,
                          negative:
                            sumBetResultByMember.me.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.me.commissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByMember.me.sumAmount > 0,
                          negative: sumBetResultByMember.me.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(sumBetResultByMember.me.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByMember.company.shareAmount > 0,
                          negative:
                            sumBetResultByMember.company.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.company.shareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByMember.company.winAmount > 0,
                          negative: sumBetResultByMember.company.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.company.winAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByMember.company.commissionAmount > 0,
                          negative:
                            sumBetResultByMember.company.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.company.commissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: sumBetResultByMember.company.sumAmount > 0,
                          negative: sumBetResultByMember.company.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByMember.company.sumAmount
                          )
                        }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </template>
            </CDataTable>
          </b-card-text>
        </b-tab>
        <b-tab
          title="แยกตามประเภท"
          @click="getByCategoryData()"
          :active="$route.query.page == 'by-category'"
        >
          <b-card-text>
            <loading v-show="isLoading" />
            <div class="table-responsive" v-show="!isLoading">
              <table
                class="
                  table table-bordered table-striped table-dark table-white
                  report
                "
              >
                <thead>
                  <tr>
                    <th rowspan="2">ประเภท</th>
                    <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
                    <th :colspan="colspan" class="br-white">สมาชิก</th>
                    <th :colspan="colspan" class="br-white">
                      {{ user.levelTH }} [{{ user.username }}]
                    </th>
                    <th :colspan="colspan">บริษัท</th>
                  </tr>
                  <tr>
                    <!-- member -->
                    <th>ยอดส่งออก</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>คอม</th>
                    <th class="br-white">รวม</th>

                    <!-- me -->
                    <th>ถือหุ้น</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>คอม</th>
                    <th class="br-white">รวม</th>

                    <!-- company -->
                    <th>ถือหุ้น</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>คอม</th>
                    <th>รวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in lottoCategorys" :key="index">
                    <td>{{ item.name }}</td>
                    <td class="bg-blue br-white">
                      {{ util.formatMoney(item.betResult.buyAmount) }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.member.buyAmount > 0,
                          negative: item.betResult.member.buyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.buyAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.member.winAmount > 0,
                          negative: item.betResult.member.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.member.commissionAmount > 0,
                          negative: item.betResult.member.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            item.betResult.member.commissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: item.betResult.member.sumAmount > 0,
                          negative: item.betResult.member.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.me.shareAmount > 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.shareAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.me.winAmount > 0,
                          negative: item.betResult.me.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.me.commissionAmount > 0,
                          negative: item.betResult.me.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.commissionAmount)
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: item.betResult.me.sumAmount > 0,
                          negative: item.betResult.me.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.shareAmount > 0,
                          negative: item.betResult.company.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.shareAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.company.winAmount > 0,
                          negative: item.betResult.company.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.commissionAmount > 0,
                          negative: item.betResult.company.commissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            item.betResult.company.commissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.sumAmount > 0,
                          negative: item.betResult.company.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.sumAmount)
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-left">รวม:</td>

                    <!-- member -->
                    <td class="bg-blue br-white">
                      {{
                        util.formatMoney(sumBetResultByCategory.sumBuyAmount)
                      }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumBuyAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumBuyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumBuyAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumWinAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumCommissionAmount >
                            0,
                          negative:
                            sumBetResultByCategory.member.sumCommissionAmount <
                            0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumCommissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumSumAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumSumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.me.sumShareAmount > 0,
                          negative:
                            sumBetResultByCategory.me.sumShareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumShareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByCategory.me.sumWinAmount > 0,
                          negative: sumBetResultByCategory.me.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.me.sumCommissionAmount > 0,
                          negative:
                            sumBetResultByCategory.me.sumCommissionAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumCommissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByCategory.me.sumSumAmount > 0,
                          negative: sumBetResultByCategory.me.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumSumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumShareAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumShareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumShareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumWinAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumCommissionAmount >
                            0,
                          negative:
                            sumBetResultByCategory.company.sumCommissionAmount <
                            0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumCommissionAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumSumAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumSumAmount
                          )
                        }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldsByMember: [
        {
          key: "username",
        },
        {
          key: "levelTH",
        },
        {
          key: "member_buyAmount",
          _classes: "bg-blue br-white",
        },
        {
          key: "member_sendAmount",
        },
        {
          key: "member_winAmount",
        },
        {
          key: "member_commissionAmount",
        },
        {
          key: "member_sumAmount",
          _classes: "br-white",
        },
        {
          key: "me_shareAmount",
        },
        {
          key: "me_winAmount",
        },
        {
          key: "me_commissionAmount",
        },
        {
          key: "me_sumAmount",
          _classes: "br-white",
        },
        {
          key: "company_shareAmount",
        },
        {
          key: "company_winAmount",
        },
        {
          key: "company_commissionAmount",
        },
        {
          key: "company_sumAmount",
        },
      ],
      fieldsWaitingByMember: [
        {
          key: "username",
        },
        {
          key: "levelTH",
        },
        {
          key: "member_buyAmount",
          _classes: "bg-blue br-white",
        },
        {
          key: "member_sendAmount",
        },
        {
          key: "member_commissionAmount",
        },
        {
          key: "member_sumAmount",
          _classes: "br-white",
        },
        {
          key: "me_shareAmount",
        },
        {
          key: "me_commissionAmount",
        },
        {
          key: "me_sumAmount",
          _classes: "br-white",
        },
        {
          key: "company_shareAmount",
        },
        {
          key: "company_commissionAmount",
        },
        {
          key: "company_sumAmount",
        },
      ],
      tableLoadingByMember: true,
      sumBetResultByMember: {
        member: {},
        me: {},
        company: {},
      },
      betResultByMembers: [],
      betResultByCategorys: [],
      sumBetResultByCategory: { member: {}, me: {}, company: {} },
      user: {},
      lottoCategorys: [],
      isLoading: true,
      isWaitingPage:
        this.$route.name == "ItemByRound" ||
        this.$route.name == "ItemUserByRound",
      showMemberName: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    roundId() {
      this.getByMemberData()
    },
  },
  computed: {
    colspan() {
      return this.isWaitingPage ? "3" : "4"
    },
    pageName() {
      return this.isWaitingPage ? "ดูของ สมาชิก/ประเภท" : "แพ้-ชนะ สมาชิกประเภท"
    },
    roundId() {
      return this.$store.state.auth.roundId
    },
  },
  methods: {
    getNameRouterLink(level) {
      if (level == "MEMBER")
        return this.isWaitingPage ? "ItemOfMember" : "ReportByMemberOfNumber"

      return this.isWaitingPage ? "ItemUserByRound" : "ReportUserByMember"
    },
    getFields() {
      return this.isWaitingPage
        ? this.fieldsWaitingByMember
        : this.fieldsByMember
    },
    getClassAmount(amount) {
      if (parseFloat(amount) > 0) {
        return "positive"
      } else if (parseFloat(amount) < 0) {
        return "negative"
      }
      return ""
    },
    async getByCategoryData() {
      this.$route.query.page = "by-category"

      return this.isWaitingPage
        ? await this.loadWaitingByCategoryData()
        : await this.loadByCategoryData()
    },
    async getByMemberData() {
      this.$route.query.page = "by-member"

      return this.isWaitingPage
        ? await this.loadWaitingByMemberData()
        : await this.loadByMemberData()
    },
    async loadByCategoryData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-result/by-category",
          params: {
            huayRoundId: this.roundId,
            userId: this.$route.params.userId,
          },
        })

        this.makeReportByCategory(res.data.data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadByMemberData() {
      this.tableLoadingByMember = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-result/by-member",
          params: {
            huayRoundId: this.roundId,
            userId: this.$route.params.userId,
          },
        })

        this.makeReportByMember(res.data.data)

        this.tableLoadingByMember = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadWaitingByCategoryData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-waiting-result/by-category",
          params: {
            huayRoundId: this.roundId,
            userId: this.$route.params.userId,
          },
        })

        this.makeReportByCategory(res.data.data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadWaitingByMemberData() {
      this.tableLoadingByMember = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-waiting-result/by-member",
          params: {
            huayRoundId: this.roundId,
            userId: this.$route.params.userId,
          },
        })

        this.makeReportByMember(res.data.data)

        this.tableLoadingByMember = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    makeReportByCategory(data) {
      let sumBetResultByCategory = {
        sumBuyAmount: 0.0,
        member: {
          sumBuyAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
        me: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
        company: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
      }

      data.huayCategorys?.forEach((item) => {
        let betResult = data.userBetResultByCategorys?.find(
          (item2) => item2.huayCategoryId == item.id
        )

        if (!betResult) {
          item.betResult = {
            buyAmount: 0.0,
            member: {
              buyAmount: 0.0,
              winAmount: 0.0,
              commissionAmount: 0.0,
              sumAmount: 0.0,
            },
            me: {
              shareAmount: 0.0,
              winAmount: 0.0,
              commissionAmount: 0.0,
              sumAmount: 0.0,
            },
            company: {
              shareAmount: 0.0,
              winAmount: 0.0,
              commissionAmount: 0.0,
              sumAmount: 0.0,
            },
          }
          return
        }

        betResult.me = {
          shareAmount: parseFloat(betResult.buyAmountReceive),
          winAmount: this.isWaitingPage
            ? 0.0
            : parseFloat(betResult.payAmountActual),
          commissionAmount: parseFloat(betResult.commissionAmount),
        }

        betResult.me.sumAmount =
          betResult.me.shareAmount +
          betResult.me.winAmount +
          betResult.me.commissionAmount

        betResult.member = {
          buyAmount: parseFloat(betResult.buyAmountRemaining) * -1,
          winAmount: this.isWaitingPage
            ? 0.0
            : parseFloat(betResult.payAmountSumMemberActual),
          commissionAmount: parseFloat(betResult.commissionAmountSumMember),
        }

        betResult.member.sumAmount =
          betResult.member.buyAmount +
          betResult.member.commissionAmount +
          betResult.member.winAmount

        betResult.company = {
          shareAmount:
            (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
          winAmount: (betResult.member.winAmount + betResult.me.winAmount) * -1,
          commissionAmount:
            (betResult.member.commissionAmount +
              betResult.me.commissionAmount) *
            -1,
        }

        betResult.company.sumAmount =
          parseFloat(betResult.company.shareAmount) +
          parseFloat(betResult.company.winAmount) +
          parseFloat(betResult.company.commissionAmount)

        sumBetResultByCategory.sumBuyAmount += parseInt(betResult.buyAmount)

        sumBetResultByCategory.member.sumBuyAmount += betResult.member.buyAmount
        sumBetResultByCategory.member.sumWinAmount += betResult.member.winAmount
        sumBetResultByCategory.member.sumCommissionAmount +=
          betResult.member.commissionAmount
        sumBetResultByCategory.member.sumSumAmount += betResult.member.sumAmount

        sumBetResultByCategory.me.sumShareAmount += betResult.me.shareAmount
        sumBetResultByCategory.me.sumWinAmount += betResult.me.winAmount
        sumBetResultByCategory.me.sumCommissionAmount +=
          betResult.me.commissionAmount
        sumBetResultByCategory.me.sumSumAmount += betResult.me.sumAmount

        sumBetResultByCategory.company.sumShareAmount +=
          betResult.company.shareAmount
        sumBetResultByCategory.company.sumWinAmount +=
          betResult.company.winAmount
        sumBetResultByCategory.company.sumCommissionAmount +=
          betResult.company.commissionAmount
        sumBetResultByCategory.company.sumSumAmount +=
          betResult.company.sumAmount

        item.betResult = betResult
      })

      this.lottoCategorys = data.huayCategorys
      this.betResultByCategorys = data.betResultByCategorys

      this.sumBetResultByCategory = sumBetResultByCategory
    },
    makeReportByMember(data) {
      let sumBetResultByMember = {
        buyAmount: 0.0,
        member: {
          buyAmount: 0.0,
          winAmount: 0.0,
          commissionAmount: 0.0,
          sumAmount: 0.0,
        },
        me: {
          shareAmount: 0.0,
          winAmount: 0.0,
          commissionAmount: 0.0,
          sumAmount: 0.0,
        },
        company: {
          shareAmount: 0.0,
          winAmount: 0.0,
          commissionAmount: 0.0,
          sumAmount: 0.0,
        },
      }

      data.userBetResultByMembers?.forEach((item) => {
        const memberBuyAmount = item.buyAmount
        item.member_buyAmount = this.util.formatMoney(memberBuyAmount)
        const memberSendAmount = item.buyAmountRemaining * -1
        item.member_sendAmount = this.util.formatMoney(memberSendAmount)
        const memberWinAmount = this.isWaitingPage
          ? "0.0"
          : item.payAmountSumMemberActual
        item.member_winAmount = this.util.formatMoney(memberWinAmount)
        const memberCommissionAmount = item.commissionAmountSumMember
        item.member_commissionAmount = this.util.formatMoney(
          memberCommissionAmount
        )
        const memberSumAmount =
          parseFloat(memberSendAmount) +
          parseFloat(memberWinAmount) +
          parseFloat(item.commissionAmountSumMember)
        item.member_sumAmount = this.util.formatMoney(memberSumAmount)

        const meShareAmount = item.buyAmountReceive
        item.me_shareAmount = this.util.formatMoney(item.buyAmountReceive)
        const meWinAmount = this.isWaitingPage ? "0.0" : item.payAmountActual
        item.me_winAmount = this.util.formatMoney(meWinAmount)
        const meCommissionAmount = item.commissionAmount
        item.me_commissionAmount = this.util.formatMoney(item.commissionAmount)
        const meSumAmount =
          parseFloat(meShareAmount) +
          parseFloat(meWinAmount) +
          parseFloat(item.commissionAmount)
        item.me_sumAmount = this.util.formatMoney(meSumAmount)

        const companyShareAmount =
          (parseFloat(memberSendAmount) + parseFloat(meShareAmount)) * -1
        item.company_shareAmount = this.util.formatMoney(companyShareAmount)
        const companyWinAmount =
          (parseFloat(memberWinAmount) + parseFloat(meWinAmount)) * -1
        item.company_winAmount = this.util.formatMoney(companyWinAmount)
        const companyCommissionAmount =
          (parseFloat(memberCommissionAmount) +
            parseFloat(meCommissionAmount)) *
          -1
        item.company_commissionAmount = this.util.formatMoney(
          companyCommissionAmount
        )
        const companySumAmount =
          parseFloat(companyShareAmount) +
          parseFloat(companyWinAmount) +
          parseFloat(companyCommissionAmount)
        item.company_sumAmount = this.util.formatMoney(companySumAmount)

        sumBetResultByMember.buyAmount += parseFloat(memberBuyAmount)
        sumBetResultByMember.member.buyAmount += parseFloat(memberSendAmount)
        sumBetResultByMember.member.winAmount += parseFloat(memberWinAmount)
        sumBetResultByMember.member.commissionAmount += parseFloat(
          memberCommissionAmount
        )
        sumBetResultByMember.member.sumAmount += parseFloat(memberSumAmount)

        sumBetResultByMember.me.shareAmount += parseFloat(meShareAmount)
        sumBetResultByMember.me.winAmount += parseFloat(meWinAmount)
        sumBetResultByMember.me.commissionAmount +=
          parseFloat(meCommissionAmount)
        sumBetResultByMember.me.sumAmount += parseFloat(meSumAmount)

        sumBetResultByMember.company.shareAmount +=
          parseFloat(companyShareAmount)
        sumBetResultByMember.company.winAmount += parseFloat(companyWinAmount)
        sumBetResultByMember.company.commissionAmount += parseFloat(
          companyCommissionAmount
        )
        sumBetResultByMember.company.sumAmount += parseFloat(companySumAmount)

        item._cellClasses = {
          member_sendAmount: this.getClassAmount(item.member_sendAmount),
          member_winAmount: this.getClassAmount(item.member_winAmount),
          member_commissionAmount: this.getClassAmount(
            item.member_commissionAmount
          ),
          member_sumAmount: this.getClassAmount(item.member_sumAmount),
          me_shareAmount: this.getClassAmount(item.me_shareAmount),
          me_winAmount: this.getClassAmount(item.me_winAmount),
          me_commissionAmount: this.getClassAmount(item.me_commissionAmount),
          me_sumAmount: this.getClassAmount(item.me_sumAmount),
          company_shareAmount: this.getClassAmount(item.company_shareAmount),
          company_winAmount: this.getClassAmount(item.company_winAmount),
          company_commissionAmount: this.getClassAmount(
            item.company_commissionAmount
          ),
          company_sumAmount: this.getClassAmount(item.company_sumAmount),
        }
      })

      this.betResultByMembers = data.userBetResultByMembers

      this.sumBetResultByMember = sumBetResultByMember
      this.user = data.user
    },
  },
  created() {
    !this.$route.query.page || this.$route.query.page == "by-member"
      ? this.getByMemberData()
      : this.getByCategoryData()
  },
}
</script>
