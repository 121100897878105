<template>
  <div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'เพิ่มสมาชิก']" />
      <ValidationObserver ref="observer" tag="form" @submit.prevent="addMember">
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            ข้อมูลสมาชิก
          </h5>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-1 col-form-label text-md-right"
                >ระดับ:</label
              >
              <div class="col-md-2">
                <select class="form-control" v-model="level">
                  <option
                    v-bind:value="item.levelEN"
                    v-for="(item, index) in optionLevel"
                    :key="index"
                  >
                    {{ item.levelTH }}
                  </option>
                </select>
              </div>
              <label
                class="col-md-1 col-form-label text-md-right"
                v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
              >
                จำนวนเงิน:
              </label>
              <div
                class="col-md-2"
                v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
              >
                <ValidationProvider
                  :rules="`required|min_value:0|max_value:${user.creditBalance}`"
                  v-slot="v"
                >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="จำนวนเงิน"
                    step="1"
                    v-model="creditBalance"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <span
                class="col-md-2"
                v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
              >
                *สูงสุด {{ util.formatMoney(user.creditBalance) }}
              </span>
            </div>
            <div class="form-group row">
              <label class="col-md-1 col-form-label text-md-right"
                >ชื่อผู้ใช้:</label
              >
              <div class="col-md-2">
                <ValidationProvider rules="required|alpha_num" v-slot="v">
                  <div class="input-group">
                    <div
                      class="input-group-prepend"
                      v-if="user.level != 'COMPANY'"
                    >
                      <span class="input-group-text">{{ user.username }}</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ชื่อผู้ใช้"
                      v-model="username"
                    />
                  </div>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-md-1 col-form-label text-md-right"
                >รหัสผ่าน:</label
              >
              <div class="col-md-2">
                <ValidationProvider rules="required|min:6" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="รหัสผ่าน"
                    v-model="password"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-2">* อย่างน้อย 6 ตัวอักษร</div>
            </div>
            <div class="form-group row">
              <label class="col-md-1 col-form-label text-md-right"
                >ชื่อสกุล:</label
              >
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="ชื่อสกุล"
                  v-model="name"
                />
              </div>
              <label class="col-md-1 col-form-label text-md-right"
                >เบอร์โทร:</label
              >
              <div class="col-md-2">
                <ValidationProvider rules="numeric" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="เบอร์โทร"
                    v-model="phoneNo"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card border-primary"
          v-if="
            level != 'COMPANY' && level != 'MEMBER' && level != 'CALL_CENTER'
          "
        >
          <h5 class="card-header border-primary bg-primary text-white">
            แบ่งหุ้น / เก็บของ
          </h5>
          <div class="card-body">
            <div class="form-inline">
              <span class="mr-3"
                >ได้รับสูงสุด: {{ maxUserHuayTypeShare.sharePercent }}%</span
              >
              <div class="form-group mr-3">
                <label
                  class="col-form-control form-control-sm text-md-right mr-1"
                  >แบ่งหุ้น:</label
                >
                <select
                  class="form-control form-control-sm"
                  v-model="giveShare"
                  @change="setKeepShares"
                >
                  <option
                    v-for="(item, index) in giveShares"
                    :key="index"
                    v-bind:value="item"
                  >
                    {{ item }}%
                  </option>
                </select>
              </div>
              <div class="form-group mr-3">
                <label
                  class="col-form-control form-control-sm text-md-right mr-1"
                  >เก็บของ:</label
                >
                <select
                  class="form-control form-control-sm"
                  v-model="keepShare"
                  @change="setKeepSharePercentAll"
                >
                  <option
                    v-for="(item, index) in keepShares"
                    :key="index"
                    v-bind:value="item"
                  >
                    {{ item }}%
                  </option>
                </select>
              </div>
              <div class="form-check mr-2">
                <input
                  id="inputKeepShare"
                  type="checkbox"
                  class="form-check-input"
                  v-model="keepBetExceed"
                  @change="setKeepBetExceedAll"
                />
                <label class="form-check-label" for="inputKeepShare"
                  >เก็บของที่เกิน</label
                >
              </div>
              <button
                class="btn btn-info"
                @click="showDetailSetSharePercent = !showDetailSetSharePercent"
                type="button"
              >
                เพิ่มเติม
              </button>
            </div>
            <div class="table-responsive mt-2" v-if="showDetailSetSharePercent">
              <table
                class="table table-sm table-bordered table-dark text-center"
              >
                <thead>
                  <tr>
                    <th rowspan="2" class="align-middle">ชนิดหวย</th>
                    <th rowspan="2" class="align-middle">แบ่งหุ้น</th>
                    <th rowspan="2" class="align-middle">เก็บของ</th>
                    <th rowspan="2" class="align-middle">เก็บของเกิน</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, index) in huayGroups">
                    <tr :key="'name' + index">
                      <td colspan="9" class="bg-primary">{{ item.name }}</td>
                    </tr>
                    <tr
                      :key="'type' + huayType.id"
                      v-for="huayType in filterHuayTypes(item.id)"
                    >
                      <td>{{ huayType.name }}</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          v-model="huayType.userHuayTypeShare.sharePercent"
                          @change="
                            huayType.userHuayTypeShare.keepSharePercent =
                              huayType.userHuayTypeShare.userSharePercent -
                              huayType.userHuayTypeShare.sharePercent
                          "
                        >
                          <option
                            v-for="(item2, index2) in huayType.userHuayTypeShare
                              .sharePercents"
                            :key="index2"
                            v-bind:value="item2"
                          >
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          v-model="huayType.userHuayTypeShare.keepSharePercent"
                        >
                          <option
                            v-for="(item2, index2) in makeKeepShareItem(
                              huayType.userHuayTypeShare
                            )"
                            :key="index2"
                            v-bind:value="item2"
                          >
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          v-model="huayType.userHuayTypeShare.keepBetExceed"
                        />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="card border-primary"
          v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
        >
          <h5 class="card-header border-primary bg-primary text-white">
            ข้อมูลการแทง
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-hover table-dark
                  text-center
                  fs-075
                "
              >
                <thead>
                  <tr>
                    <th rowspan="2">ประเภท</th>
                    <th colspan="3">3 ตัวท้าย / 3 ตัวหัว</th>
                  </tr>
                  <tr>
                    <th>ขั้นต่ำ</th>
                    <th>สูงสุดต่อโพย</th>
                    <th>สูงสุดต่อเลข</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in categoryList" :key="index">
                    <td class="text-gold">{{ item.name }}</td>
                    <td>
                      <ValidationProvider
                        :rules="`required|min_value:${item.fixBuyMin}`"
                        v-slot="v"
                        v-if="showItem(item.status)"
                      >
                        <div class="form-inline">
                          <input
                            type="number"
                            class="form-control form-control-sm mr-1"
                            v-model.number="item.buyMin"
                            @keypress="$root.onlyNumber"
                          />
                          <span>/{{ item.fixBuyMin }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider
                        :rules="`required|min_value:0|max_value:${item.fixBuyMaxPerBill}`"
                        v-slot="v"
                        v-if="showItem(item.status)"
                      >
                        <div class="form-inline">
                          <input
                            type="number"
                            class="form-control form-control-sm mr-1"
                            v-model.number="item.buyMaxPerBill"
                            @keypress="$root.onlyNumber"
                          /><span>/{{ item.fixBuyMaxPerBill }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider
                        :rules="`required|min_value:0|max_value:${item.fixBuyMaxPerNumber}`"
                        v-slot="v"
                        v-if="showItem(item.status)"
                      >
                        <div class="form-inline">
                          <input
                            type="number"
                            class="form-control form-control-sm mr-1"
                            v-model.number="item.buyMaxPerNumber"
                            @keypress="$root.onlyNumber"
                          />
                          <span>/{{ item.fixBuyMaxPerNumber }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="card border-primary"
          v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
        >
          <h5 class="card-header border-primary bg-primary text-white">
            อัตราจ่าย เปิด/ปิด
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-hover table-dark
                  text-center
                  fs-075
                "
              >
                <thead>
                  <tr>
                    <th>อัตราจ่าย</th>
                    <th>เปิด/ปิด</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in payList" :key="index">
                    <td class="text-gold">{{ item.name }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="item.open"
                        v-if="showItem(item.status)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="card border-primary"
          v-if="level != 'COMPANY' && level != 'CALL_CENTER'"
        >
          <h5 class="card-header border-primary bg-primary text-white">
            อัตราจ่ายและคอมมิชชั่น
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-hover table-dark
                  text-center
                  fs-075
                "
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th v-for="(item, index) in categoryList" :key="index">
                      {{ item.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in payList">
                    <tr :key="item.id">
                      <td rowspan="2" class="text-gold">{{ item.name }}</td>
                      <td>อัตราจ่าย</td>
                      <td
                        v-for="(item2, index2) in filterCategoryPayList(
                          item.huayPayId
                        )"
                        :key="index2"
                      >
                        <ValidationProvider
                          :rules="`required|min_value:0|max_value:${item2.fixPayRate}`"
                          v-slot="v"
                          v-if="showItem(item2.status)"
                        >
                          <div class="d-flex align-items-center min-width-5">
                            <input
                              type="number"
                              class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.payrate"
                            />
                            <span>/{{ item2.fixPayRate }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr :key="item.id">
                      <td>คอมมิชชั่น</td>
                      <td
                        v-for="(item2, index2) in filterCategoryPayList(
                          item.huayPayId
                        )"
                        :key="index2"
                      >
                        <ValidationProvider
                          :rules="`required|min_value:0|max_value:${item2.fixCommission}`"
                          v-slot="v"
                          v-if="showItem(item2.status)"
                        >
                          <div class="d-flex align-items-center min-width-5">
                            <input
                              type="number"
                              class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.commission"
                            />
                            <span>/{{ item2.fixCommission }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">เพิ่มสมาชิก</button>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 1rem;
}

.form-pay {
  width: 60px;
}
</style>

<script>
export default {
  name: "AddMember",
  data() {
    return {
      username: "",
      password: "",
      phoneNo: "",
      level: "",
      name: "",
      creditMaximum: 0,
      creditBalance: 0,
      optionLevels: [
        { levelTH: "บริษัท", levelEN: "COMPANY", level: 99 },
        // { levelTH: "คอลเซ็นเตอร์", levelEN: "CALL_CENTER", level: 30 },
        { levelTH: "พาร์ทเนอร์", levelEN: "PARTNER", level: 20 },
        { levelTH: "เอเย่นต์", levelEN: "AGENT", level: 10 },
        { levelTH: "เมมเบอร์", levelEN: "MEMBER", level: 0 },
      ],
      categoryList: [],
      payList: [],
      categoryPayList: [],
      user: {},
      giveShares: [],
      giveShare: 0,
      keepShares: [],
      keepShare: 0,
      keepBetExceed: false,
      isLoading: true,
      huayTypes: [],
      huayGroups: [],
      showDetailSetSharePercent: false,
      maxUserHuayTypeShare: {},
      userHuayTypeShares: [],
    }
  },
  computed: {
    optionLevel() {
      var levels = this.optionLevels.filter(
        (item) => item.level <= this.user.levelNumber
      )
      if (this.user.level == "COMPANY") {
        levels = levels.filter(
          (item) =>
            ["COMPANY", "CALL_CENTER", "PARTNER"].indexOf(item.levelEN) != -1
        )
      } else if (this.user.level == "PARTNER") {
        levels = levels.filter(
          (item) => ["AGENT", "MEMBER"].indexOf(item.levelEN) != -1
        )
      }
      return levels
    },
  },
  methods: {
    setKeepBetExceedAll() {
      this.userHuayTypeShares.forEach(
        (item) => (item.keepBetExceed = this.keepBetExceed)
      )
    },
    setSharePercentAll() {
      this.userHuayTypeShares.forEach((item) => {
        if (this.giveShare <= item.userSharePercent) {
          item.sharePercent = this.giveShare
          item.keepSharePercent = item.userSharePercent - item.sharePercent
        } else {
          item.sharePercent = item.userSharePercent
          item.keepSharePercent = 0
        }
      })
    },
    setKeepSharePercentAll() {
      this.userHuayTypeShares.forEach((item) => {
        if (this.giveShare == item.sharePercent) {
          item.keepSharePercent = this.keepShare
        }
      })
    },
    makeSharePercentItem(item) {
      let sharePercents = []
      for (let i = parseInt(item.sharePercent); i >= 0; i -= 0.5) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      for (let i = item.keepSharePercent; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    findUserHuayTypeSharesByTypeId(typeId) {
      return this.userHuayTypeSharesForm.find(
        (item) => item.huayTypeId == typeId
      )
    },
    filterHuayTypes(groupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == groupId)
    },
    showItem(status) {
      return status == "OPEN"
    },
    setKeepShares() {
      this.keepShare = this.maxUserHuayTypeShare.sharePercent - this.giveShare
      this.keepShares = []
      for (let i = this.keepShare; i >= 0; i -= 0.5) {
        this.keepShares.push(i)
      }

      this.setSharePercentAll()
    },
    filterCategoryPayList(huayPayId) {
      return this.categoryPayList.filter((item) => item.huayPayId == huayPayId)
    },
    async addMember() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "auth/register",
          data: {
            user: {
              username: this.username,
              password: this.password,
              phoneNo: this.phoneNo,
              level: this.level,
              name: this.name,
              creditBalance: this.creditBalance,
              sharePercent: this.giveShare,
              keepSharePercent: this.keepShare,
              keepBetExceed: this.keepBetExceed,
            },
            categoryList: this.categoryList,
            payList: this.payList,
            categoryPayList: this.categoryPayList,
            userHuayTypeShares: this.userHuayTypeShares,
          },
        })

        let result = await this.$swal({
          icon: "success",
          text: "เพิ่มสมาชิกสำเร็จ",
        })
        if (result.isConfirmed) {
          this.$store.dispatch("auth/updateUserData")
          this.$router.push("/members/edit")
        }
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เพิ่มสมาชิกไม่สำเร็จ",
          text: e.response.data.description,
        })
      }
    },
    async loadData() {
      try {
        let response = await this.axios.get("users/add-member-data")
        let data = response.data.data

        data.userHuayTypeShares.forEach((item) => {
          item.sharePercents = this.makeSharePercentItem(item)
          item.userSharePercent = item.sharePercent
          item.keepSharePercent = item.userSharePercent
          item.sharePercent = 0
          item.keepBetExceed = false
        })

        data.huayTypes.forEach((item) => {
          item.userHuayTypeShare = data.userHuayTypeShares.find(
            (item2) => item.id == item2.huayTypeId
          )
        })

        this.userHuayTypeShares = data.userHuayTypeShares
        this.huayTypes = data.huayTypes
        this.huayGroups = data.huayGroups
        this.maxUserHuayTypeShare = data.maxUserHuayTypeShare

        data.categoryList?.forEach((item) => {
          item.fixBuyMaxPerBill = item.buyMaxPerBill
          item.fixBuyMaxPerNumber = item.buyMaxPerNumber
          item.fixBuyMin = item.buyMin
        })

        this.categoryList = data.categoryList

        this.payList = data.payList
        this.payList?.forEach((item) => {
          data.categoryPayList.forEach((item2) => {
            if (item.huayPayId == item2.huayPayId) {
              item2.status = item.status
            }
          })
        })

        data.categoryPayList?.forEach((item) => {
          item.fixPayRate = item.payrate
          item.fixCommission = item.commission
        })
        this.categoryPayList = data.categoryPayList

        this.$store.state.auth.user.userData = data.user
        this.user = data.user

        if (data.user.level == "PARTNER") {
          this.level = "AGENT"
        } else {
          this.level = data.user.level
        }

        for (let i = this.maxUserHuayTypeShare.sharePercent; i >= 0; i -= 0.5) {
          this.giveShares.push(i)
        }

        this.keepShare = this.maxUserHuayTypeShare.sharePercent
        for (let i = this.keepShare; i >= 0; i -= 0.5) {
          this.keepShares.push(i)
        }

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>
