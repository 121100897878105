<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['จัดการสมาชิก', 'เปิด-ปิด หวย/อัตราจ่าย']"
    />
    <user-nav name="เปิด-ปิด หวย/อัตราจ่าย"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div v-if="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showMemberName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showMemberName"
          />
          <label for="showMemberName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs v-model="tabMainIndex" card>
            <b-tab
              title="เปิด/ปิด กลุ่มหวย"
              class="p-0"
              :active="!$route.query.page || $route.query.page == 'group'"
              @click="setPage('group')"
            >
              <b-card-text v-if="tabMainIndex === 0">
                <b-tabs v-model="tabHuayGroupIndex" card>
                  <b-tab
                    :title="item.name"
                    v-for="(item, index) in huayGroups"
                    :key="index"
                    :active="$route.query.id == item.id"
                    @click="setId(item.id)"
                  >
                    <b-card-text v-if="tabHuayGroupIndex === index">
                      <div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th colspan="2">
                                <button
                                  class="btn btn-success mr-1"
                                  @click="saveHuayGroup(item.id, 'OPEN')"
                                >
                                  เปิด
                                </button>
                                <button
                                  class="btn btn-danger mr-1"
                                  @click="saveHuayGroup(item.id, 'CLOSE')"
                                >
                                  ปิด
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllHuayGroup[index]"
                                  @change="
                                    toggleCheckAllHuayGroup(item.id, index)
                                  "
                                />
                              </th>
                              <th>สถานะ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(member, index2) in filterUserHuayGroup(
                                item.id
                              )"
                              :key="index2"
                              :class="{
                                'table-danger': member.userStatus == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  v-if="
                                    member.level == 'PARTNER' ||
                                    member.level == 'AGENT'
                                  "
                                  :to="{
                                    name: 'EditLottoOpenCloseMemberByUserId',
                                    params: { userId: member.userId },
                                    query: {
                                      page: 'group',
                                      id: item.id,
                                    },
                                  }"
                                >
                                  {{ member.username }}
                                </router-link>
                                <span
                                  v-if="
                                    member.level != 'PARTNER' &&
                                    member.level != 'AGENT'
                                  "
                                  >{{ member.username }}</span
                                >
                                <span v-if="showMemberName"
                                  >({{ member.memberName }})</span
                                >
                              </td>
                              <td>{{ member.levelTH }}</td>
                              <td>
                                <input type="checkbox" v-model="member.check" />
                              </td>
                              <td>
                                {{
                                  huayGroupStatus(member.userId, item.id) ==
                                  "OPEN"
                                    ? "เปิด"
                                    : "ปิด"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="เปิด/ปิด อัตราจ่าย"
              class="p-0"
              :active="$route.query.page == 'payRate'"
              @click="setPage('payRate')"
            >
              <b-card-text v-if="tabMainIndex === 1">
                <b-tabs v-model="tabHuayPayIndex" card>
                  <b-tab
                    :title="item.name"
                    v-for="(item, index) in huayPays"
                    :key="index"
                    :active="$route.query.id == item.id"
                    @click="setId(item.id)"
                  >
                    <b-card-text v-if="tabHuayPayIndex === index">
                      <div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th colspan="2">
                                <button
                                  class="btn btn-success mr-1"
                                  @click="saveHuayPay(item.id, 'OPEN')"
                                >
                                  เปิด
                                </button>
                                <button
                                  class="btn btn-danger mr-1"
                                  @click="saveHuayPay(item.id, 'CLOSE')"
                                >
                                  ปิด
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllHuayPay[index]"
                                  @change="
                                    toggleCheckAllHuayPay(item.id, index)
                                  "
                                />
                              </th>
                              <th>สถานะ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(member, index2) in filterUserHuayPay(
                                item.id
                              )"
                              :key="index2"
                              :class="{
                                'table-danger': member.userStatus == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  v-if="
                                    member.level == 'PARTNER' ||
                                    member.level == 'AGENT'
                                  "
                                  :to="{
                                    name: 'EditLottoOpenCloseMemberByUserId',
                                    params: { userId: member.userId },
                                    query: {
                                      page: 'payRate',
                                      id: item.id,
                                    },
                                  }"
                                >
                                  {{ member.username }}
                                </router-link>
                                <span
                                  v-if="
                                    member.level != 'PARTNER' &&
                                    member.level != 'AGENT'
                                  "
                                  >{{ member.username }}</span
                                >
                                <span v-if="showMemberName"
                                  >({{ member.memberName }})</span
                                >
                              </td>
                              <td>{{ member.levelTH }}</td>
                              <td>
                                <input type="checkbox" v-model="member.check" />
                              </td>
                              <td>
                                {{
                                  huayPayStatus(member.userId, item.id) ==
                                  "OPEN"
                                    ? "เปิด"
                                    : "ปิด"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMemberName: false,
      isLoading: true,
      isNotFound: false,
      userHuayGroups: [],
      huayGroups: [],
      userHuayPays: [],
      huayPays: [],
      checkAllHuayGroup: [],
      checkAllHuayPay: [],
      tabMainIndex: 0,
      tabHuayGroupIndex: 0,
      tabHuayPayIndex: 0,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setId(val) {
      this.$route.query.id = val
    },
    filterUserHuayGroup(huayGroupId) {
      return this.userHuayGroups.filter(
        (item) => item.huayGroupId == huayGroupId
      )
    },
    filterUserHuayPay(huayPayId) {
      return this.userHuayPays.filter((item) => item.huayPayId == huayPayId)
    },
    toggleCheckAllHuayGroup(huayGroupId, index) {
      this.userHuayGroups.forEach((item) => {
        if (item.huayGroupId == huayGroupId) {
          item.check = this.checkAllHuayGroup[index]
        }
      })
    },
    toggleCheckAllHuayPay(huayPayId, index) {
      this.userHuayPays.forEach((item) => {
        if (item.huayPayId == huayPayId) {
          item.check = this.checkAllHuayPay[index]
        }
      })
    },
    huayGroupStatus(userId, huayGroupId) {
      let result = this.userHuayGroups.find(
        (item) => item.userId == userId && item.huayGroupId == huayGroupId
      )
      if (result) {
        return result.status
      }
    },
    huayPayStatus(userId, huayPayId) {
      let result = this.userHuayPays.find(
        (item) => item.userId == userId && item.huayPayId == huayPayId
      )
      if (result) {
        return result.status
      }
    },
    async saveHuayGroup(huayGroupId, status) {
      let userHuayGroups = this.userHuayGroups.filter(
        (item) => item.check && item.huayGroupId == huayGroupId
      )

      if (userHuayGroups.length == 0) {
        return
      }

      userHuayGroups.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/openclose-huay-group",
          data: {
            userHuayGroups: userHuayGroups,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        }).then(this.initData())
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async saveHuayPay(huayPayId, status) {
      let userHuayPays = this.userHuayPays.filter(
        (item) => item.check && item.huayPayId == huayPayId
      )

      if (userHuayPays.length == 0) {
        return
      }

      userHuayPays.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/openclose-huay-pay",
          data: {
            userHuayPays: userHuayPays,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
      } catch (e) {
        await this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "users/openclose-huay",
          params: {
            userId: this.$route.params.userId,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }
        let data = res.data.data

        data.userHuayGroups.forEach((item) => {
          let member = data.members.find((item2) => item2.id == item.userId)
          if (member) {
            item.userStatus = member.status
            item.username = member.username
            item.memberName = member.name
            item.levelTH = member.levelTH
            item.level = member.level
            item.userId = member.id
            item.check = false
          }
        })

        data.userHuayPays.forEach((item) => {
          let member = data.members.find((item2) => item2.id == item.userId)
          if (member) {
            item.userStatus = member.status
            item.username = member.username
            item.memberName = member.name
            item.levelTH = member.levelTH
            item.level = member.level
            item.userId = member.id
            item.check = false
          }
        })

        this.userHuayGroups = data.userHuayGroups
        this.huayGroups = data.huayGroups
        this.huayPays = data.huayPays
        this.userHuayPays = data.userHuayPays

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
