export function formatMoney(amount) {
  if (!amount) {
    return "0.00"
  }
  return parseFloat(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2
  })
}

export function formatMoneyNormal(amount) {
  if (amount === 0) {
    return "0"
  }

  if (!amount) {
    return ""
  }

  return parseFloat(amount).toLocaleString("en-US")
}

export function getClassAmount(a) {
  if (!a || parseFloat(a) == parseFloat(0)) {
    return ""
  }
  if (parseFloat(a) > parseFloat(0)) {
    return "positive"
  }
  return "negative"
}
