<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="[
        isRouteReportByDate ? 'รายงานแพ้ชนะ' : 'รายการแทง',
        pageName,
      ]"
    />
    <user-nav :name="pageName"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isRouteReportByDate">
      แสดงข้อมูลงวดที่ ออกรางวัลแล้ว และเป็นผลรวม เท่านั้น
    </div>
    <div class="alert alert-info" role="alert" v-if="!isRouteReportByDate">
      แสดงข้อมูลงวดที่ ยังไม่ออกรางวัล
    </div>
    <div
      class="card border-primary"
      style="width: 700px"
      v-if="isRouteReportByDate"
    >
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex mb-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="fromDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <span class="mx-2 my-auto">ถึง</span>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="toDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <button class="btn btn-primary ml-2" @click="loadByTypeData">
            ค้นหา
          </button>
        </div>

        <div class="d-flex">
          <button
            v-for="(item, index) in searchDateButtons"
            :key="index"
            class="btn btn-sm btn-warning mr-2"
            @click="setDateRange(item.key)"
          >
            {{ item.name }}
          </button>
          <button
            class="btn btn-sm btn-outline-warning"
            @click="showSearchSelectType = !showSearchSelectType"
          >
            ชนิดหวย
          </button>
        </div>

        <div class="mt-3" v-if="showSearchSelectType">
          <div class="mb-2">
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="setSearchTypeAll(true)"
            >
              เลือกทั้งหมด
            </button>
            <button
              class="btn btn-sm btn-danger"
              @click="setSearchTypeAll(false)"
            >
              ไม่เลือกเลย
            </button>
          </div>
          <div
            v-for="(group, index) in lottoGroups"
            :key="index"
            class="row mb-2"
          >
            <div class="col-12 mb-2 bg-primary">
              {{ group.name }}
            </div>
            <div
              class="col-3"
              style="font-size: 0.8rem"
              v-for="(type, index) in filterLottoTypeByGroupId(group.id)"
              :key="index"
            >
              <input
                v-model="type.searchChecked"
                :id="'selext-type-' + type.id"
                type="checkbox"
                class="mr-1"
              />
              <label :for="'selext-type-' + type.id">{{ type.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive mt-3" v-show="!isLoading">
      <table
        class="
          table table-sm table-bordered table-striped table-dark table-white
          report
        "
      >
        <thead>
          <tr>
            <th rowspan="2">
              <div class="d-flex flex-column">
                <span>ชื่อผู้ใช้</span>
                <div class="d-flex justify-content-center">
                  <input
                    id="showMemberName"
                    type="checkbox"
                    class="cursor-pointer mr-1 my-auto"
                    v-model="showMemberName"
                  />
                  <label for="showMemberName" class="my-auto">แสดงชื่อ</label>
                </div>
              </div>
            </th>
            <th rowspan="2">ระดับชั้น</th>
            <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
            <th :colspan="isRouteReportByDate ? 4 : 3" class="br-white">
              สมาชิก
            </th>
            <th :colspan="isRouteReportByDate ? 4 : 3" class="br-white">
              {{ user.levelTH }} [{{ user.username }}]
            </th>
            <th :colspan="isRouteReportByDate ? 4 : 3">บริษัท</th>
          </tr>
          <tr>
            <th>ยอดส่งออก</th>
            <th v-if="isRouteReportByDate">ถูกรางวัล</th>
            <th>คอม</th>
            <th class="br-white">รวม</th>
            <th>ถือหุ้น</th>
            <th v-if="isRouteReportByDate">ถูกรางวัล</th>
            <th>คอม</th>
            <th class="br-white">รวม</th>
            <th>ถือหุ้น</th>
            <th v-if="isRouteReportByDate">ถูกรางวัล</th>
            <th>คอม</th>
            <th>รวม</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(group, index) in lottoGroupsReport">
            <!-- <tr :key="'group' + index" class="bg-primary">
              <td colspan="99" class="bg-primary text-left">
                {{ group.name }}
              </td>
            </tr> -->
            <template
              v-for="(item, indexType) in filterLottoTypeReportByGroupId(
                group.id
              )"
            >
              <tr :key="'mtType' + index + indexType" v-if="showMemberName">
                <td colspan="3" class="br-white"></td>
                <td
                  :colspan="isRouteReportByDate ? 4 : 3"
                  class="br-white"
                ></td>
                <td
                  :colspan="isRouteReportByDate ? 4 : 3"
                  class="br-white"
                ></td>
                <td
                  :colspan="isRouteReportByDate ? 4 : 3"
                  class="br-white"
                ></td>
              </tr>
              <tr
                :key="'header' + index + indexType"
                :class="{ 'bg-green3': showMemberName }"
              >
                <td class="text-left" colspan="2">
                  <span>{{ item.name }}</span>
                </td>

                <td class="br-white" :class="{ 'bg-blue': !showMemberName }">
                  {{ util.formatMoney(item.sumBetResultByType.sumBuyAmount) }}
                </td>

                <!-- member -->
                <td>
                  <span
                    :class="{
                      positive: item.sumBetResultByType.member.sumBuyAmount > 0,
                      negative: item.sumBetResultByType.member.sumBuyAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.member.sumBuyAmount
                      )
                    }}</span
                  >
                </td>
                <td v-if="isRouteReportByDate">
                  <span
                    :class="{
                      positive: item.sumBetResultByType.member.sumWinAmount > 0,
                      negative: item.sumBetResultByType.member.sumWinAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.member.sumWinAmount
                      )
                    }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.member.sumCommissionAmount > 0,
                      negative:
                        item.sumBetResultByType.member.sumCommissionAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.member.sumCommissionAmount
                      )
                    }}</span
                  >
                </td>
                <td class="br-white">
                  <span
                    :class="{
                      positive: item.sumBetResultByType.member.sumSumAmount > 0,
                      negative: item.sumBetResultByType.member.sumSumAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.member.sumSumAmount
                      )
                    }}</span
                  >
                </td>

                <!-- me -->
                <td>
                  <span
                    :class="{
                      positive: item.sumBetResultByType.me.sumShareAmount > 0,
                      negative: item.sumBetResultByType.me.sumShareAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.me.sumShareAmount
                      )
                    }}</span
                  >
                </td>
                <td v-if="isRouteReportByDate">
                  <span
                    :class="{
                      positive: item.sumBetResultByType.me.sumWinAmount > 0,
                      negative: item.sumBetResultByType.me.sumWinAmount < 0,
                    }"
                    >{{
                      util.formatMoney(item.sumBetResultByType.me.sumWinAmount)
                    }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.me.sumCommissionAmount > 0,
                      negative:
                        item.sumBetResultByType.me.sumCommissionAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.me.sumCommissionAmount
                      )
                    }}</span
                  >
                </td>
                <td class="br-white">
                  <span
                    :class="{
                      positive: item.sumBetResultByType.me.sumSumAmount > 0,
                      negative: item.sumBetResultByType.me.sumSumAmount < 0,
                    }"
                    >{{
                      util.formatMoney(item.sumBetResultByType.me.sumSumAmount)
                    }}</span
                  >
                </td>

                <!-- company -->
                <td>
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.company.sumShareAmount > 0,
                      negative:
                        item.sumBetResultByType.company.sumShareAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.company.sumShareAmount
                      )
                    }}</span
                  >
                </td>
                <td v-if="isRouteReportByDate">
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.company.sumWinAmount > 0,
                      negative:
                        item.sumBetResultByType.company.sumWinAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.company.sumWinAmount
                      )
                    }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.company.sumCommissionAmount > 0,
                      negative:
                        item.sumBetResultByType.company.sumCommissionAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.company.sumCommissionAmount
                      )
                    }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive:
                        item.sumBetResultByType.company.sumSumAmount > 0,
                      negative:
                        item.sumBetResultByType.company.sumSumAmount < 0,
                    }"
                    >{{
                      util.formatMoney(
                        item.sumBetResultByType.company.sumSumAmount
                      )
                    }}</span
                  >
                </td>
              </tr>
              <template v-if="showMemberName">
                <tr
                  :key="'body' + index + indexType + index2"
                  v-for="(betResult, index2) in item.betResults"
                >
                  <td class="text-left">
                    <router-link
                      class="mr-1"
                      :to="{
                        name: getNameRouterLink(betResult.level),
                        params: { userId: betResult.userId },
                        query: {
                          typeId: item.id,
                          types: searchTypeSelected,
                          fromDate: fromDate,
                          toDate: toDate,
                        },
                      }"
                      >{{ betResult.username }}</router-link
                    ><span v-if="showMemberName">({{ betResult.name }})</span>
                  </td>
                  <td>{{ betResult.levelTH }}</td>
                  <td class="bg-blue br-white">
                    {{ util.formatMoney(betResult.buyAmount) }}
                  </td>
                  <td>
                    <span
                      :class="{
                        positive: betResult.member.buyAmount > 0,
                        negative: betResult.member.buyAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.member.buyAmount) }}</span
                    >
                  </td>
                  <td v-if="isRouteReportByDate">
                    <span
                      :class="{
                        positive: betResult.member.winAmount > 0,
                        negative: betResult.member.winAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.member.winAmount) }}</span
                    >
                  </td>
                  <td>
                    <span
                      :class="{
                        positive: betResult.member.commissionAmount > 0,
                        negative: betResult.member.commissionAmount < 0,
                      }"
                      >{{
                        util.formatMoney(betResult.member.commissionAmount)
                      }}</span
                    >
                  </td>
                  <td class="br-white">
                    <span
                      :class="{
                        positive: betResult.member.sumAmount > 0,
                        negative: betResult.member.sumAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.member.sumAmount) }}</span
                    >
                  </td>

                  <!-- me -->
                  <td>
                    <span
                      :class="{
                        positive: betResult.me.shareAmount > 0,
                      }"
                      >{{ util.formatMoney(betResult.me.shareAmount) }}</span
                    >
                  </td>
                  <td v-if="isRouteReportByDate">
                    <span
                      :class="{
                        positive: betResult.me.winAmount > 0,
                        negative: betResult.me.winAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.me.winAmount) }}</span
                    >
                  </td>
                  <td>
                    <span
                      :class="{
                        positive: betResult.me.commissionAmount > 0,
                        negative: betResult.me.commissionAmount < 0,
                      }"
                      >{{
                        util.formatMoney(betResult.me.commissionAmount)
                      }}</span
                    >
                  </td>
                  <td class="br-white">
                    <span
                      :class="{
                        positive: betResult.me.sumAmount > 0,
                        negative: betResult.me.sumAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.me.sumAmount) }}</span
                    >
                  </td>

                  <!-- company -->
                  <td>
                    <span
                      :class="{
                        positive: betResult.company.shareAmount > 0,
                        negative: betResult.company.shareAmount < 0,
                      }"
                      >{{
                        util.formatMoney(betResult.company.shareAmount)
                      }}</span
                    >
                  </td>
                  <td v-if="isRouteReportByDate">
                    <span
                      :class="{
                        positive: betResult.company.winAmount > 0,
                        negative: betResult.company.winAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.company.winAmount) }}</span
                    >
                  </td>
                  <td>
                    <span
                      :class="{
                        positive: betResult.company.commissionAmount > 0,
                        negative: betResult.company.commissionAmount < 0,
                      }"
                      >{{
                        util.formatMoney(betResult.company.commissionAmount)
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      :class="{
                        positive: betResult.company.sumAmount > 0,
                        negative: betResult.company.sumAmount < 0,
                      }"
                      >{{ util.formatMoney(betResult.company.sumAmount) }}</span
                    >
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <div class="mt-4" v-if="!isLoading">
      <h4>รวมทั้งหมด</h4>
      <div class="table-responsive" v-if="!isLoading">
        <table
          class="
            table table-sm table-bordered table-striped table-dark table-white
            report
          "
        >
          <thead>
            <tr>
              <th rowspan="2">
                <div class="d-flex flex-column">
                  <span>ชื่อผู้ใช้</span>
                  <div class="d-flex justify-content-center">
                    <input
                      id="showAllMemberName"
                      type="checkbox"
                      class="cursor-pointer mr-1 my-auto"
                      v-model="showAllMemberName"
                    />
                    <label for="showAllMemberName" class="my-auto"
                      >แสดงชื่อ</label
                    >
                  </div>
                </div>
              </th>
              <th rowspan="2">ระดับชั้น</th>
              <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
              <th :colspan="isRouteReportByDate ? 4 : 3" class="br-white">
                สมาชิก
              </th>
              <th :colspan="isRouteReportByDate ? 4 : 3" class="br-white">
                {{ user.levelTH }} [{{ user.username }}]
              </th>
              <th :colspan="isRouteReportByDate ? 4 : 3">บริษัท</th>
            </tr>
            <tr>
              <th>ยอดส่งออก</th>
              <th v-if="isRouteReportByDate">ถูกรางวัล</th>
              <th>คอม</th>
              <th class="br-white">รวม</th>
              <th>ถือหุ้น</th>
              <th v-if="isRouteReportByDate">ถูกรางวัล</th>
              <th>คอม</th>
              <th class="br-white">รวม</th>
              <th>ถือหุ้น</th>
              <th v-if="isRouteReportByDate">ถูกรางวัล</th>
              <th>คอม</th>
              <th>รวม</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(betResult, index) in betResultByUsers" :key="index">
              <td class="text-left">
                <router-link
                  v-if="betResult.level != 'MEMBER'"
                  :to="{
                    name: isRouteReportByDate
                      ? 'ReportUserByDate'
                      : 'ReportWaitingUserByDate',
                    params: { userId: betResult.userId },
                    query: {
                      types: searchTypeSelected,
                      fromDate: fromDate,
                      toDate: toDate,
                    },
                  }"
                  >{{ betResult.username }}</router-link
                >
                <span v-if="betResult.level == 'MEMBER'">{{
                  betResult.username
                }}</span>
                <span v-if="showAllMemberName" class="ml-1"
                  >({{ betResult.name }})</span
                >
              </td>
              <td>{{ betResult.levelTH }}</td>
              <td class="bg-blue br-white">
                {{ util.formatMoney(betResult.buyAmount) }}
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.member.buyAmount > 0,
                    negative: betResult.member.buyAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.buyAmount) }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: betResult.member.winAmount > 0,
                    negative: betResult.member.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.member.commissionAmount > 0,
                    negative: betResult.member.commissionAmount < 0,
                  }"
                  >{{
                    util.formatMoney(betResult.member.commissionAmount)
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: betResult.member.sumAmount > 0,
                    negative: betResult.member.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.sumAmount) }}</span
                >
              </td>

              <!-- me -->
              <td>
                <span
                  :class="{
                    positive: betResult.me.shareAmount > 0,
                  }"
                  >{{ util.formatMoney(betResult.me.shareAmount) }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: betResult.me.winAmount > 0,
                    negative: betResult.me.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.me.commissionAmount > 0,
                    negative: betResult.me.commissionAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.commissionAmount) }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: betResult.me.sumAmount > 0,
                    negative: betResult.me.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.sumAmount) }}</span
                >
              </td>

              <!-- company -->
              <td>
                <span
                  :class="{
                    positive: betResult.company.shareAmount > 0,
                    negative: betResult.company.shareAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.shareAmount) }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: betResult.company.winAmount > 0,
                    negative: betResult.company.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.company.commissionAmount > 0,
                    negative: betResult.company.commissionAmount < 0,
                  }"
                  >{{
                    util.formatMoney(betResult.company.commissionAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.company.sumAmount > 0,
                    negative: betResult.company.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.sumAmount) }}</span
                >
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-left" colspan="2">รวม:</td>

              <!-- member -->
              <td class="bg-blue br-white">
                {{ util.formatMoney(sumBetResultByUser.sumBuyAmount) }}
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumBuyAmount > 0,
                    negative: sumBetResultByUser.member.sumBuyAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumBuyAmount)
                  }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumWinAmount > 0,
                    negative: sumBetResultByUser.member.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumCommissionAmount > 0,
                    negative: sumBetResultByUser.member.sumCommissionAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      sumBetResultByUser.member.sumCommissionAmount
                    )
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumSumAmount > 0,
                    negative: sumBetResultByUser.member.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumSumAmount)
                  }}</span
                >
              </td>

              <!-- me -->
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumShareAmount > 0,
                    negative: sumBetResultByUser.me.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumShareAmount)
                  }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumWinAmount > 0,
                    negative: sumBetResultByUser.me.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumCommissionAmount > 0,
                    negative: sumBetResultByUser.me.sumCommissionAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumCommissionAmount)
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumSumAmount > 0,
                    negative: sumBetResultByUser.me.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumSumAmount)
                  }}</span
                >
              </td>

              <!-- company -->
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumShareAmount > 0,
                    negative: sumBetResultByUser.company.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumShareAmount)
                  }}</span
                >
              </td>
              <td v-if="isRouteReportByDate">
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumWinAmount > 0,
                    negative: sumBetResultByUser.company.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive:
                      sumBetResultByUser.company.sumCommissionAmount > 0,
                    negative:
                      sumBetResultByUser.company.sumCommissionAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      sumBetResultByUser.company.sumCommissionAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumSumAmount > 0,
                    negative: sumBetResultByUser.company.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumSumAmount)
                  }}</span
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllMemberName: false,
      showMemberName: false,
      showSearchSelectType: false,
      searchDateButtons: [
        {
          name: "วันนี้",
          key: "TODAY",
        },
        {
          name: "เมื่อวาน",
          key: "YESTERDAY",
        },
        {
          name: "สัปดาห์นี้",
          key: "WEEK",
        },
        {
          name: "สัปดาห์ที่แล้ว",
          key: "LASTWEEK",
        },
        {
          name: "เดือนนี้",
          key: "MONTH",
        },
        {
          name: "เดือนที่แล้ว",
          key: "LASTMONTH",
        },
      ],
      betResultByTypes: [],
      betResultByUsers: [],
      sumBetResultByUser: {},
      lottoTypes: [],
      fromDate: this.$route.query.fromDate
        ? this.$route.query.fromDate
        : this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$route.query.toDate
        ? this.$route.query.toDate
        : this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      isLoading: true,
      isRouteReportByDate:
        this.$route.name == "ReportByDate" ||
        this.$route.name == "ReportUserByDate",
      user: {},
      lottoTypeAll: [],
    }
  },
  computed: {
    lottoGroupsReport() {
      return this.lottoGroups.filter((item) =>
        this.lottoTypes.find((item2) => item.id == item2.huayGroupId)
      )
    },
    lottoGroups() {
      return this.$store.state.lotto.lottoGroups
    },
    pageName() {
      return this.isRouteReportByDate ? "แพ้-ชนะ สุทธิ" : "รอผลเดิมพัน"
    },
    searchTypeSelected() {
      let isNotSelect = this.lottoTypeAll.find((item) => !item.searchChecked)
      if (isNotSelect) {
        return this.lottoTypeAll
          .filter((item) => item.searchChecked)
          .map(function (item) {
            return item.id
          })
          .join(",")
      }

      return ""
    },
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    filterLottoTypeReportByGroupId(groupId) {
      return this.lottoTypes.filter((item) => item.huayGroupId == groupId)
    },
    getNameRouterLink(level) {
      if (level == "MEMBER")
        return this.isRouteReportByDate
          ? "ReportByDateOfMember"
          : "ItemByDateOfMember"

      return this.isRouteReportByDate
        ? "ReportUserByDate"
        : "ReportWaitingUserByDate"
    },
    setSearchTypeAll(val) {
      this.lottoTypeAll.forEach((item) => (item.searchChecked = val))
    },
    filterLottoTypeByGroupId(groupId) {
      return this.lottoTypeAll.filter((item) => item.huayGroupId == groupId)
    },
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadByTypeData()
    },
    makeReport(data) {
      data.huayTypes?.forEach((item) => {
        let betResults = data.betResultByTypes?.filter(
          (item2) => item2.huayTypeId == item.id
        )

        if (!betResults || betResults.length == 0) {
          return
        }

        item.betResults = []

        let sumBetResultByType = {
          sumBuyAmount: 0.0,
          member: {
            sumBuyAmount: 0.0,
            sumWinAmount: 0.0,
            sumCommissionAmount: 0.0,
            sumSumAmount: 0.0,
          },
          me: {
            sumShareAmount: 0.0,
            sumWinAmount: 0.0,
            sumCommissionAmount: 0.0,
            sumSumAmount: 0.0,
          },
          company: {
            sumShareAmount: 0.0,
            sumWinAmount: 0.0,
            sumCommissionAmount: 0.0,
            sumSumAmount: 0.0,
          },
        }

        betResults.forEach((betResult) => {
          betResult.me = {
            shareAmount: parseFloat(betResult.buyAmountReceive),
            winAmount: this.isRouteReportByDate
              ? parseFloat(betResult.payAmountActual)
              : 0.0,
            commissionAmount: parseFloat(betResult.commissionAmount),
          }

          betResult.me.sumAmount =
            betResult.me.shareAmount +
            betResult.me.winAmount +
            betResult.me.commissionAmount

          betResult.member = {
            buyAmount: parseFloat(betResult.buyAmountRemaining) * -1,
            winAmount: this.isRouteReportByDate
              ? parseFloat(betResult.payAmountSumMemberActual)
              : 0.0,
            commissionAmount: parseFloat(betResult.commissionAmountSumMember),
          }

          betResult.member.sumAmount =
            betResult.member.buyAmount +
            betResult.member.commissionAmount +
            betResult.member.winAmount

          betResult.company = {
            shareAmount:
              (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
            winAmount:
              (betResult.member.winAmount + betResult.me.winAmount) * -1,
            commissionAmount:
              (betResult.member.commissionAmount +
                betResult.me.commissionAmount) *
              -1,
          }

          betResult.company.sumAmount =
            parseFloat(betResult.company.shareAmount) +
            parseFloat(betResult.company.winAmount) +
            parseFloat(betResult.company.commissionAmount)

          sumBetResultByType.sumBuyAmount += parseFloat(betResult.buyAmount)

          sumBetResultByType.member.sumBuyAmount += betResult.member.buyAmount
          sumBetResultByType.member.sumWinAmount += betResult.member.winAmount
          sumBetResultByType.member.sumCommissionAmount +=
            betResult.member.commissionAmount
          sumBetResultByType.member.sumSumAmount += betResult.member.sumAmount

          sumBetResultByType.me.sumShareAmount += betResult.me.shareAmount
          sumBetResultByType.me.sumWinAmount += betResult.me.winAmount
          sumBetResultByType.me.sumCommissionAmount +=
            betResult.me.commissionAmount
          sumBetResultByType.me.sumSumAmount += betResult.me.sumAmount

          sumBetResultByType.company.sumShareAmount +=
            betResult.company.shareAmount
          sumBetResultByType.company.sumWinAmount += betResult.company.winAmount
          sumBetResultByType.company.sumCommissionAmount +=
            betResult.company.commissionAmount
          sumBetResultByType.company.sumSumAmount += betResult.company.sumAmount

          item.betResults.push(betResult)
        })

        item.sumBetResultByType = sumBetResultByType
      })

      if (this.lottoTypeAll.length == 0) {
        let types = this.$route.query.types

        this.lottoTypeAll = data.huayTypes.map((item) => {
          if (!types) {
            item.searchChecked = true
          } else {
            item.searchChecked = types
              .split(",")
              .find((item2) => item2 == item.id)
              ? true
              : false
          }

          return item
        })
      }

      this.lottoTypes = data.huayTypes.filter((item) => item.betResults)

      this.betResultByTypes = data.betResultByTypes

      let sumBetResultByUser = {
        sumBuyAmount: 0.0,
        member: {
          sumBuyAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
        me: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
        company: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumCommissionAmount: 0.0,
          sumSumAmount: 0.0,
        },
      }

      data.betResultByUsers?.forEach((betResult) => {
        betResult.me = {
          shareAmount: parseFloat(betResult.buyAmountReceive),
          winAmount: this.isRouteReportByDate
            ? parseFloat(betResult.payAmountActual)
            : 0.0,
          commissionAmount: parseFloat(betResult.commissionAmount),
        }

        betResult.me.sumAmount =
          betResult.me.shareAmount +
          betResult.me.winAmount +
          betResult.me.commissionAmount

        betResult.member = {
          buyAmount: parseFloat(betResult.buyAmountRemaining) * -1,
          winAmount: this.isRouteReportByDate
            ? parseFloat(betResult.payAmountSumMemberActual)
            : 0.0,
          commissionAmount: parseFloat(betResult.commissionAmountSumMember),
        }

        betResult.member.sumAmount =
          betResult.member.buyAmount +
          betResult.member.commissionAmount +
          betResult.member.winAmount

        betResult.company = {
          shareAmount:
            (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
          winAmount: (betResult.member.winAmount + betResult.me.winAmount) * -1,
          commissionAmount:
            (betResult.member.commissionAmount +
              betResult.me.commissionAmount) *
            -1,
        }

        betResult.company.sumAmount =
          parseFloat(betResult.company.shareAmount) +
          parseFloat(betResult.company.winAmount) +
          parseFloat(betResult.company.commissionAmount)

        sumBetResultByUser.sumBuyAmount += parseFloat(betResult.buyAmount)

        sumBetResultByUser.member.sumBuyAmount += betResult.member.buyAmount
        sumBetResultByUser.member.sumWinAmount += betResult.member.winAmount
        sumBetResultByUser.member.sumCommissionAmount +=
          betResult.member.commissionAmount
        sumBetResultByUser.member.sumSumAmount += betResult.member.sumAmount

        sumBetResultByUser.me.sumShareAmount += betResult.me.shareAmount
        sumBetResultByUser.me.sumWinAmount += betResult.me.winAmount
        sumBetResultByUser.me.sumCommissionAmount +=
          betResult.me.commissionAmount
        sumBetResultByUser.me.sumSumAmount += betResult.me.sumAmount

        sumBetResultByUser.company.sumShareAmount +=
          betResult.company.shareAmount
        sumBetResultByUser.company.sumWinAmount += betResult.company.winAmount
        sumBetResultByUser.company.sumCommissionAmount +=
          betResult.company.commissionAmount
        sumBetResultByUser.company.sumSumAmount += betResult.company.sumAmount
      })

      this.betResultByUsers = data.betResultByUsers
      this.sumBetResultByUser = sumBetResultByUser
      this.user = data.user
    },
    async loadByTypeData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-result/by-type",
          params: {
            startTime: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            endTime: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            userId: this.$route.params.userId,
            types: this.$route.query.types
              ? this.$route.query.types
              : this.searchTypeSelected,
          },
        })

        let data = res.data.data

        this.makeReport(data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadWaitingByTypeData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-waiting/by-type",
          params: {
            userId: this.$route.params.userId,
          },
        })

        let data = res.data.data

        this.makeReport(data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  created() {
    this.$store.dispatch("lotto/getLottoGroupList", {}, { root: true })

    this.isRouteReportByDate
      ? this.loadByTypeData()
      : this.loadWaitingByTypeData()
  },
}
</script>
