<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าบัญชี']" />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        เปลี่ยนรหัสผ่าน
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="changePassword"
          novalidate
        >
          <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-md-9 col-lg-6 col-xl-5">
              <div class="form-group row">
                <label for="old-password" class="col-12 col-sm-4">
                  รหัสผ่านเดิม:
                </label>
                <div class="col-12 col-sm-8">
                  <ValidationProvider rules="required" v-slot="v">
                    <input
                      id="old-password"
                      type="password"
                      class="form-control mr-1"
                      v-model="form.oldPassword"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label for="new-password" class="col-12 col-sm-4">
                  รหัสผ่านใหม่:
                </label>
                <div class="col-12 col-sm-8">
                  <ValidationProvider
                    name="password"
                    vid="password"
                    rules="required|min:6"
                    v-slot="v"
                  >
                    <input
                      id="new-password"
                      type="password"
                      class="form-control mr-1"
                      v-model="form.newPassword"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label for="new-password-confirm" class="col-12 col-sm-4">
                  รหัสผ่านใหม่ อีกครั้ง:
                </label>
                <div class="col-12 col-sm-8">
                  <ValidationProvider
                    rules="required|password_confirm:@password"
                    v-slot="v"
                  >
                    <input
                      id="new-password-confirm"
                      type="password"
                      class="form-control mr-1"
                      v-model="form.newPasswordConfirm"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="text-right">
                <button class="btn btn-primary" type="submit">
                  เปลี่ยนรหัสผ่าน
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AuthProvider from "@/resources/auth_provider";
const authService = new AuthProvider();

export default {
  name: "ProfileSetting",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
    };
  },
  methods: {
    async changePassword() {
      let observer = this.$refs.observer;
      let isValid = await observer.validate();
      if (!isValid) {
        this.focusError(observer);
        return;
      }
      this.swal.processing();
      const res = await authService.changePassword({
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
      });
      if (res.error) {
        this.swal.swalError(res.error.message);
      } else {
        this.swal.swalSuccess("เปลี่ยนรหัสผ่านสำเร็จ");
      }
    },
  },
};
</script>