<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'แบ่งหุ้น/เก็บของ']" />
    <user-nav name="แบ่งหุ้น/เก็บของ"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              class="p-0"
              v-for="(group, groupIndex) in huayGroups"
              :key="groupIndex"
              :title="group.name"
              :active="$route.query.groupId == group.id"
              @click="loadData(filterHuayTypeByGroup(group.id)[0])"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="huayType.name"
                    v-for="(huayType, index) in filterHuayTypeByGroup(group.id)"
                    :key="index"
                    :active="$route.query.typeId == huayType.id"
                    @click="loadData(huayType)"
                  >
                    <b-card-text>
                      <div class="d-flex mb-3">
                        <h5 class="my-auto mr-2">
                          ตั้งค่ากลุ่มหวยตาม{{ huayType.name }} :
                        </h5>
                        <button
                          class="btn btn-sm btn-warning mr-2"
                          v-for="(item, index2) in huayGroups"
                          :key="index2"
                          @click="setGroupLikeType(huayType.id, item.id)"
                        >
                          กลุ่ม{{ item.name }}
                        </button>
                      </div>
                      <div class="table-responsive">
                        <table
                          class="
                            table table-sm table-bordered table-hover table-dark
                            text-center
                          "
                        >
                          <thead>
                            <tr class="bg-info">
                              <th>
                                <span>ชื่อผู้ใช้</span>
                                <div class="d-flex justify-content-center">
                                  <input
                                    id="showMemberName"
                                    type="checkbox"
                                    class="cursor-pointer mr-1 my-auto"
                                    v-model="showMemberName"
                                  />
                                  <label for="showMemberName" class="my-auto"
                                    >แสดงชื่อ</label
                                  >
                                </div>
                              </th>
                              <th>ระดับ</th>
                              <th>แบ่งหุ้น</th>
                              <th>เก็บของ</th>
                              <th>เก็บของเกิน</th>
                              <td>บันทึก</td>
                              <td class="bg-dark">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="saveSelected(huayType)"
                                >
                                  บันทึกที่เลือก
                                </button>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="bg-secondary"
                              v-for="(
                                item, index2
                              ) in filterUserRefHuayTypeSharesByTypeId(
                                huayType.id
                              )"
                              :key="'head' + index2"
                            >
                              <td colspan="2">เปลี่ยนทั้งหมด</td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.sharePercent"
                                >
                                  <option
                                    v-for="(
                                      item2, index3
                                    ) in makeSharePercentAll(item)"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.keepSharePercent"
                                >
                                  <option
                                    v-for="(item2, index3) in makeKeepShareItem(
                                      item
                                    )"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <div class="form-check">
                                  <input
                                    :id="`inputKeepShareAll${item.id}`"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="item.keepBetExceed"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`inputKeepShareAll${item.id}`"
                                    >เก็บของที่เกิน</label
                                  >
                                </div>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="saveAll(huayType)"
                                >
                                  บันทึก
                                </button>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="huayType.checked"
                                  @change="setCheckMember(huayType)"
                                />
                              </td>
                            </tr>
                            <tr
                              v-for="(
                                item, index2
                              ) in huayType.userHuayTypeShares"
                              :key="index2"
                              :class="{
                                'table-danger': item.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  v-if="
                                    item.level == 'PARTNER' ||
                                    item.level == 'AGENT'
                                  "
                                  :to="{
                                    name: 'ShareMemberByUserId',
                                    params: { userId: item.userId },
                                    query: {
                                      typeId: huayType.id,
                                      groupId: group.id,
                                    },
                                  }"
                                >
                                  {{ item.username }} </router-link
                                ><span v-if="showMemberName"
                                  >({{ item.name }})</span
                                >
                                <span
                                  v-if="
                                    item.level != 'PARTNER' &&
                                    item.level != 'AGENT'
                                  "
                                  >{{ item.username }}</span
                                >
                              </td>
                              <td>{{ item.levelTH }}</td>
                              <td>
                                <select
                                  v-if="
                                    item.level == 'PARTNER' ||
                                    item.level == 'AGENT'
                                  "
                                  class="form-control form-control-sm"
                                  v-model="item.sharePercent"
                                  @change="setKeepSharePercent(item)"
                                >
                                  <option
                                    v-for="(
                                      item2, index3
                                    ) in makeSharePercentItem(item)"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  v-if="
                                    item.level == 'PARTNER' ||
                                    item.level == 'AGENT' ||
                                    item.level == 'MEMBER'
                                  "
                                  class="form-control form-control-sm"
                                  v-model="item.keepSharePercent"
                                >
                                  <option
                                    v-for="(item2, index3) in makeKeepShareItem(
                                      item
                                    )"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <div
                                  class="form-check"
                                  v-if="
                                    item.level == 'PARTNER' ||
                                    item.level == 'AGENT' ||
                                    item.level == 'MEMBER'
                                  "
                                >
                                  <input
                                    :id="`inputKeepShare${item.id}`"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="item.keepBetExceed"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`inputKeepShare${item.id}`"
                                    >เก็บของที่เกิน</label
                                  >
                                </div>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="save(item)"
                                >
                                  บันทึก
                                </button>
                              </td>
                              <td>
                                <input type="checkbox" v-model="item.checked" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  th {
    padding: 10px;
  }

  td {
    padding: 5px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      showMemberName: false,
      huayTypes: [],
      huayGroups: [],
      userRefHuayTypeShares: [],
      userRefHuayTypeSharesForm: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  methods: {
    setCheckMember(huayType) {
      huayType.userHuayTypeShares.forEach((item) => {
        item.checked = huayType.checked
      })
    },
    setGroup(id) {
      this.$route.query.groupId = id
    },
    filterHuayTypeByGroup(groupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == groupId)
    },
    setKeepSharePercent(item) {
      let sharePercent = this.findUserRefHuayTypeSharesByTypeId(
        item.huayTypeId
      ).sharePercent

      item.keepSharePercent = sharePercent - item.sharePercent
    },
    makeSharePercentAll(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findUserRefHuayTypeSharesByTypeId(item.huayTypeId)
            .oldSharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }

      return sharePercents
    },
    makeSharePercentItem(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findUserRefHuayTypeSharesByTypeId(item.huayTypeId).sharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      let sharePercent = this.findUserRefHuayTypeSharesByTypeId(
        item.huayTypeId
      ).sharePercent
      let keepShare =
        item.level == "MEMBER" ? sharePercent : sharePercent - item.sharePercent

      for (let i = keepShare; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    filterUserRefHuayTypeSharesByTypeId(typeId) {
      return this.userRefHuayTypeShares.filter(
        (item) => item.huayTypeId == typeId
      )
    },
    findUserRefHuayTypeSharesByTypeId(typeId) {
      return this.userRefHuayTypeSharesForm.find(
        (item) => item.huayTypeId == typeId
      )
    },
    setPage(val) {
      this.$route.query.page = val
    },
    async setGroupLikeType(huayTypeId, huayGroupId) {
      this.swal.processing()

      try {
        await this.axios({
          method: "put",
          url: "users/type/share/by-group",
          params: {
            userId: this.$route.params.userId,
            huayTypeId: huayTypeId,
            huayGroupId: huayGroupId,
          },
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async save(item) {
      this.swal.processing()

      try {
        await this.axios({
          method: "put",
          url: "users/type/share",
          data: [item],
          params: {
            userId: this.$route.params.userId,
          },
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async saveSelected(huayType) {
      this.swal.processing()

      let data = huayType.userHuayTypeShares.filter((item) => item.checked)

      try {
        await this.axios({
          method: "put",
          url: "users/type/share",
          data: data,
          params: {
            userId: this.$route.params.userId,
          },
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async saveAll(huayType) {
      this.swal.processing()

      const userRefHuayTypeShare = this.userRefHuayTypeShares.filter(
        (item) => item.huayTypeId == huayType.id
      )[0]

      huayType.userHuayTypeShares.forEach((item) => {
        item.sharePercent = userRefHuayTypeShare.sharePercent
        item.keepSharePercent = userRefHuayTypeShare.keepSharePercent
        item.keepBetExceed = userRefHuayTypeShare.keepBetExceed
      })

      try {
        await this.axios({
          method: "put",
          url: "users/type/share",
          data: huayType.userHuayTypeShares,
          params: {
            userId: this.$route.params.userId,
          },
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadData(huayType) {
      this.isLoading = true

      this.$route.query.typeId = huayType
        ? huayType.id
        : this.$route.query.typeId

      this.$route.query.groupId = huayType
        ? huayType.huayGroupId
        : this.$route.query.groupId

      try {
        let res = await this.axios({
          method: "get",
          url: `users/type/${this.$route.query.typeId}/share`,
          params: {
            userId: this.$route.params.userId,
          },
        })

        let data = res.data.data

        if (data.userHuayTypeShares == null) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        this.huayGroups = data.huayGroups
        this.huayTypes = data.huayTypes.map((item) => {
          item.checked = false

          item.userHuayTypeShares = data.userHuayTypeShares
            ?.filter((item2) => item2.huayTypeId == item.id)
            .sort((a, b) => (a.userId < b.userId ? 1 : -1))

          return item
        })

        data.userRefHuayTypeShares.forEach((item) => {
          item.oldSharePercent = item.sharePercent
          item.keepSharePercent = "0"
          item.checked = false
        })

        this.userRefHuayTypeShares = data.userRefHuayTypeShares

        this.userRefHuayTypeSharesForm = JSON.parse(
          JSON.stringify(data.userRefHuayTypeShares)
        )

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>
