<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'ออกผล']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">ออกผล</h5>
      <div class="card-body">
        <loading v-show="isLoading" />
        <div v-show="!isLoading">
          <div class="form-group row">
            <div class="col-md-1 text-md-right">#ID:</div>
            <div class="col-md-11">{{ data.id }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-1 text-md-right">ชื่อชนิด:</div>
            <div class="col-md-11">{{ data.typeName }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-1 text-md-right">ชื่องวด:</div>
            <div class="col-md-11">{{ data.name }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-1 text-md-right">สถาน:</div>
            <div class="col-md-11">
              {{ data.status == "SUCCESS" ? "ออกผลแล้ว" : "เปิดอยู่" }}
            </div>
          </div>
          <div class="form-group row pb-4 mb-4 border-bottom">
            <div class="col-md-1 text-md-right">เวลาเปิดปิด:</div>
            <div class="col-md-11 mb-3">
              {{ $date(data.openTime).format("DD/MM/YYYY HH:mm") }} -
              {{ $date(data.closeTime).format("DD/MM/YYYY HH:mm") }}
            </div>
          </div>
          <template v-if="data.status == 'OPEN'">
            <div class="row border rounded p-3 mb-3">
              <div class="col-12 text-center mb-3">
                <span
                  class="badge badge-pill badge-danger"
                  style="font-size: 1.2rem"
                  >admin 1</span
                >
              </div>
              <div
                class="form-group col-3"
                v-for="item in huayCategoryTypes"
                :key="item.id"
              >
                <div class="row">
                  <label class="col-4 text-right my-auto"
                    >{{ item.name }}:</label
                  >
                  <input
                    :disabled="data.disabled || item.autoFill"
                    type="text"
                    class="form-control col"
                    v-model="item.result"
                    v-on:change="autoFill"
                  />
                </div>
              </div>
            </div>
            <div class="row border rounded p-3 mb-3">
              <div class="col-12 text-center mb-3">
                <span
                  class="badge badge-pill badge-danger"
                  style="font-size: 1.2rem"
                  >admin 2</span
                >
              </div>
              <div
                class="form-group col-3"
                v-for="item in categoryTypesAdmin2"
                :key="item.id"
              >
                <div class="row">
                  <label class="col-4 text-right my-auto"
                    >{{ item.name }}:</label
                  >
                  <input
                    disabled
                    type="text"
                    class="form-control col"
                    :value="item.result"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-if="data.status != 'OPEN'">
            <div class="row">
              <div
                class="form-group col-3"
                v-for="item in huayCategoryTypes"
                :key="item.id"
              >
                <div class="row">
                  <label class="col-4 text-right my-auto"
                    >{{ item.name }}:</label
                  >
                  <input
                    :disabled="data.disabled || item.autoFill"
                    type="text"
                    class="form-control col"
                    v-model="item.result"
                    v-on:change="autoFill"
                  />
                </div>
              </div>
            </div>
          </template>
          <div class="d-flex">
            <div class="btn btn-danger mr-2" @click="$router.go(-1)">
              ยกเลิก
            </div>
            <div class="btn btn-primary mr-2" @click="!data.disabled && save()">
              บันทึก
            </div>
            <!-- <div
              class="btn btn-info"
              v-if="data.status == 'OPEN'"
              @click="prepareResult"
            >
              ตั้งผลล่วงหน้า
            </div> -->
            <div
              class="btn btn-warning"
              v-if="data.disabled && user.level == 'SUPER_ADMIN'"
              @click="data.disabled = false"
            >
              แก้ไขผล
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      data: {},
      categoryTypesAdmin2: [],
      huayCategoryTypes: [],
      categoryTypeAutoFill: [
        {
          code: "BACK_3_TOP_CROSS",
        },
        {
          code: "BACK_2_TOP",
        },
        {
          code: "RUN_BACK_TOP",
        },
        {
          code: "RUN_BACK_BOTTOM",
        },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  methods: {
    async editResult() {
      let result = await this.swal.confirm("ยืนยันการแก้ไขผล")
      if (!result) {
        return
      }

      // let huayCategoryTypes = this.huayCategoryTypes.filter(
      //   (item) => item.oldResult != item.result && item.result != ""
      // );
      // if (huayCategoryTypes.length == 0) {
      //   this.$swal({
      //     icon: "warning",
      //     title: "ผลไม่มีการเปลี่ยนแปลง",
      //   });
      //   return;
      // }

      this.swal.processing()
      this.axios({
        method: "patch",
        url: "setting/huay-round/result",
        data: {
          huayRound: this.data,
          huayCategoryTypes: this.huayCategoryTypes,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "ออกผลสำเร็จ",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("auth/forceReload")
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.swal.swalError()
          this.$store.dispatch("auth/forceReload")
        })
    },
    async prepareResult() {
      let result = await this.swal.confirm("ยืนยันการตั้งผลล่วงหน้า")
      if (!result) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "setting/huay-round/result/prepare",
        data: {
          huayRound: this.data,
          huayCategoryTypes: this.huayCategoryTypes,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "ตั้งผลล่วงหน้า",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("auth/forceReload")
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.swal.swalError()
        })
    },
    async save() {
      if (this.data.status == "SUCCESS") {
        await this.editResult()
        return
      }

      let result = await this.swal.confirm("ยืนยันการออกผล")
      if (!result) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "setting/huay-round/result",
        data: {
          huayRound: this.data,
          huayCategoryTypes: this.huayCategoryTypes,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "ออกผลสำเร็จ",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/setting/lotto-period-by-date")
            }
          })
        })
        .catch((err) => {
          this.swal.swalError(err.response.data.description)
        })
    },
    autoFill() {
      let back3Top = this.huayCategoryTypes.find(
        (item) => item.code == "BACK_3_TOP"
      )
      if (back3Top && back3Top.result.length == 3) {
        let back3TopCross = this.huayCategoryTypes.find(
          (item) => item.code == "BACK_3_TOP_CROSS"
        )

        if (back3TopCross) {
          let result = [
            back3Top.result[0] + back3Top.result[1] + back3Top.result[2],
            back3Top.result[0] + back3Top.result[2] + back3Top.result[1],
            back3Top.result[1] + back3Top.result[0] + back3Top.result[2],
            back3Top.result[1] + back3Top.result[2] + back3Top.result[0],
            back3Top.result[2] + back3Top.result[0] + back3Top.result[1],
            back3Top.result[2] + back3Top.result[1] + back3Top.result[0],
          ]
          back3TopCross.result = [...new Set(result)].join(",")
        }

        let back2Top = this.huayCategoryTypes.find(
          (item) => item.code == "BACK_2_TOP"
        )
        if (back2Top) {
          back2Top.result = back3Top.result.substring(1, 3)
        }

        let runBackTop = this.huayCategoryTypes.find(
          (item) => item.code == "RUN_BACK_TOP"
        )
        if (runBackTop) {
          let result = [
            back3Top.result[0],
            back3Top.result[1],
            back3Top.result[2],
          ]
          runBackTop.result = [...new Set(result)].join(",")
        }
      }
      let back2Bottom = this.huayCategoryTypes.find(
        (item) => item.code == "BACK_2_BOTTOM"
      )
      if (back2Bottom && back2Bottom.result.length == 2) {
        let runBackBottom = this.huayCategoryTypes.find(
          (item) => item.code == "RUN_BACK_BOTTOM"
        )
        if (runBackBottom) {
          let result = [back2Bottom.result[0], back2Bottom.result[1]]
          runBackBottom.result = [...new Set(result)].join(",")
        }
      }
    },
  },
  mounted() {
    this.axios({
      method: "get",
      url: "setting/huay-round/result/" + this.$route.params.id,
    })
      .then((res) => {
        let data = res.data.data
        let huayRound = data.huayRound
        huayRound.disabled = huayRound.status == "SUCCESS"
        data.huayRoundResults &&
          data.huayRoundResults.forEach((item) => {
            let categoryType = data.huayCategoryTypes.find(
              (item2) => item2.huayCategoryId == item.huayCategoryId
            )
            if (categoryType) {
              categoryType.oldResult = item.result
              categoryType.result = item.result
            }
          })
        data.huayCategoryTypes.forEach((item) => {
          let autoItem = this.categoryTypeAutoFill.find(
            (item2) => item.code == item2.code
          )
          if (autoItem) {
            item.autoFill = true
          } else {
            item.autoFill = false
          }
        })
        if (data.validateCreateHuayResultTwoAdminRequest) {
          if (
            data.validateCreateHuayResultTwoAdminRequest.userId !=
            this.$store.state.auth.user.userData.id
          ) {
            data.validateCreateHuayResultTwoAdminRequest.huayCategoryTypes.forEach(
              (item) => {
                item.result = "***"
              }
            )
          }
          this.categoryTypesAdmin2 =
            data.validateCreateHuayResultTwoAdminRequest.huayCategoryTypes
        } else {
          this.categoryTypesAdmin2 = JSON.parse(
            JSON.stringify(data.huayCategoryTypes)
          )
        }
        this.huayCategoryTypes = data.huayCategoryTypes
        this.data = huayRound
        this.isLoading = false
      })
      .catch((err) => {
        console.log(err)
        this.swal.swalError()
      })
  },
}
</script>