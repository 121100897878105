<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['จัดการสมาชิก', 'อัตราจ่าย/คอมมิชชั่น']"
    />
    <user-nav name="อัตราจ่าย/คอมมิชชั่น"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showMemberName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showMemberName"
          />
          <label for="showMemberName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs v-model="tabMainIndex" card>
            <b-tab
              title="อัตราจ่าย"
              class="p-0"
              :active="!$route.query.page || $route.query.page == 'payRate'"
              @click="setPage('payRate')"
            >
              <b-card-text v-if="tabMainIndex === 0">
                <b-tabs v-model="tabPayrateIndex" card>
                  <b-tab
                    :title="huayPay.name"
                    v-for="(huayPay, index) in filterHuayPayOpen"
                    :key="index"
                    :active="$route.query.payId == huayPay.huayPayId"
                    @click="setPayId(huayPay.huayPayId)"
                  >
                    <b-card-text v-if="tabPayrateIndex === index"
                      ><div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th colspan="2">
                                <button
                                  class="btn btn-primary mr-1"
                                  @click="saveData"
                                >
                                  บันทึก
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="clearPayrate(huayPay.huayPayId)"
                                >
                                  ยกเลิก
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllPayrate"
                                  @change="toggleCheckAllPayrate"
                                />
                              </th>
                              <th
                                v-for="(
                                  userRefHuayCategoryPay, index2
                                ) in filterUserRefHuayCategoryPay(
                                  huayPay.huayPayId
                                )"
                                :key="index2"
                                class="align-top"
                              >
                                <ValidationProvider
                                  :rules="`min_value:0|numeric`"
                                  v-slot="v"
                                >
                                  <div class="d-flex flex-column min-width-5">
                                    <span>{{
                                      userRefHuayCategoryPay.name
                                    }}</span>
                                    <input
                                      type="number"
                                      class="
                                        form-control form-control-sm form-small
                                      "
                                      @keypress="$root.onlyNumber"
                                      v-model="userRefHuayCategoryPay.payrate"
                                      v-if="
                                        showItem(userRefHuayCategoryPay.status)
                                      "
                                    />
                                    <!-- <span
                                      v-if="
                                        showItem(userRefHuayCategoryPay.status)
                                      "
                                    >
                                      &#x3C;=
                                      <span class="text-gold">{{
                                        userRefHuayCategoryPay.fixPayrate
                                      }}</span>
                                    </span> -->
                                    <span class="text-danger">{{
                                      v.errors[0]
                                    }}</span>
                                  </div>
                                </ValidationProvider>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(member, index2) in members"
                              :key="index2"
                              :class="{
                                'table-danger': member.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  v-if="
                                    member.level == 'PARTNER' ||
                                    member.level == 'AGENT'
                                  "
                                  :to="{
                                    name: 'EditPayMemberByUserId',
                                    params: { userId: member.id },
                                    query: {
                                      page: 'payRate',
                                      payId: huayPay.huayPayId,
                                    },
                                  }"
                                >
                                  {{ member.username }}
                                </router-link>
                                <span
                                  v-if="
                                    member.level != 'PARTNER' &&
                                    member.level != 'AGENT'
                                  "
                                  >{{ member.username }}</span
                                >
                                <span v-if="showMemberName"
                                  >({{ member.name }})</span
                                >
                              </td>
                              <td>{{ member.levelTH }}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="member.checkPayrate"
                                />
                              </td>
                              <td
                                v-for="(
                                  userHuayCategoryPay, index3
                                ) in filterUserHuayCategoryPay(
                                  member.id,
                                  huayPay.huayPayId
                                )"
                                :key="index3"
                              >
                                <span
                                  v-if="showItem(userHuayCategoryPay.status)"
                                  >{{ userHuayCategoryPay.payrate }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="คอมมิชชั่น"
              class="p-0"
              :active="$route.query.page == 'commission'"
              @click="setPage('commission')"
            >
              <b-card-text v-if="tabMainIndex === 1"
                ><b-tabs v-model="tabComIndex" card>
                  <b-tab
                    :title="huayPay.name"
                    v-for="(huayPay, index) in filterHuayPayOpen"
                    :key="index"
                    :active="$route.query.payId == huayPay.huayPayId"
                    @click="setPayId(huayPay.huayPayId)"
                  >
                    <b-card-text v-if="tabComIndex === index"
                      ><div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th colspan="2">
                                <button
                                  class="btn btn-primary mr-1"
                                  @click="saveData"
                                >
                                  บันทึก
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="clearCommission(huayPay.huayPayId)"
                                >
                                  ยกเลิก
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllCommission"
                                  @change="toggleCheckAllCommission"
                                />
                              </th>
                              <th
                                v-for="(
                                  userRefHuayCategoryPay, index2
                                ) in filterUserRefHuayCategoryPay(
                                  huayPay.huayPayId
                                )"
                                :key="index2"
                                class="align-top"
                              >
                                <ValidationProvider
                                  :rules="`min_value:0|numeric`"
                                  v-slot="v"
                                >
                                  <div class="d-flex flex-column min-width-5">
                                    <span>{{
                                      userRefHuayCategoryPay.name
                                    }}</span>
                                    <input
                                      type="number"
                                      class="
                                        form-control form-control-sm form-small
                                      "
                                      @keypress="$root.onlyNumber"
                                      v-model="
                                        userRefHuayCategoryPay.commission
                                      "
                                      v-if="
                                        showItem(userRefHuayCategoryPay.status)
                                      "
                                    />
                                    <!-- <span
                                      v-if="
                                        showItem(userRefHuayCategoryPay.status)
                                      "
                                    >
                                      &#x3C;=
                                      <span class="text-gold">{{
                                        userRefHuayCategoryPay.fixCommission
                                      }}</span>
                                    </span> -->
                                    <span class="text-danger">{{
                                      v.errors[0]
                                    }}</span>
                                  </div>
                                </ValidationProvider>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(member, index2) in members"
                              :key="index2"
                              :class="{
                                'table-danger': member.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  v-if="
                                    member.level == 'PARTNER' ||
                                    member.level == 'AGENT'
                                  "
                                  :to="{
                                    name: 'EditPayMemberByUserId',
                                    params: { userId: member.id },
                                    query: {
                                      page: 'commission',
                                      payId: huayPay.huayPayId,
                                    },
                                  }"
                                >
                                  {{ member.username }}
                                </router-link>
                                <span
                                  v-if="
                                    member.level != 'PARTNER' &&
                                    member.level != 'AGENT'
                                  "
                                  >{{ member.username }}</span
                                >
                                <span v-if="showMemberName"
                                  >({{ member.name }})</span
                                >
                              </td>
                              <td>{{ member.levelTH }}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="member.checkCommission"
                                />
                              </td>
                              <td
                                v-for="(
                                  userHuayCategoryPay, index3
                                ) in filterUserHuayCategoryPay(
                                  member.id,
                                  huayPay.huayPayId
                                )"
                                :key="index3"
                              >
                                <span
                                  v-if="showItem(userHuayCategoryPay.status)"
                                >
                                  {{ userHuayCategoryPay.commission }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-small {
  width: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      showMemberName: false,
      checkAllPayrate: false,
      checkAllCommission: false,
      userRefHuayCategoryPays: [],
      userHuayCategoryPays: [],
      members: [],
      huayPays: [],
      isLoading: true,
      isNotFound: false,
      tabMainIndex: 0,
      tabPayrateIndex: 0,
      tabComIndex: 0,
    }
  },
  computed: {
    filterHuayPayOpen() {
      return this.huayPays.filter((item) => item.status == "OPEN")
    },
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setPayId(val) {
      this.$route.query.payId = val
      this.initData()
    },
    showItem(status) {
      return status == "OPEN"
    },
    filterUserRefHuayCategoryPay(huayPayId) {
      return this.userRefHuayCategoryPays.filter(
        (item2) => item2.huayPayId == huayPayId
      )
    },
    filterUserHuayCategoryPay(userId, huayPayId) {
      return this.userHuayCategoryPays.filter(
        (item) => item.userId == userId && item.huayPayId == huayPayId
      )
    },
    clearPayrate(huayPayId) {
      this.userRefHuayCategoryPays.forEach((item2) => {
        if (item2.huayPayId == huayPayId) {
          item2.payrate = null
        }
      })
    },
    clearCommission(huayPayId) {
      this.userRefHuayCategoryPays.forEach((item2) => {
        if (item2.huayPayId == huayPayId) {
          item2.commission = null
        }
      })
    },
    toggleCheckAllPayrate() {
      this.members.forEach((item) => {
        item.checkPayrate = this.checkAllPayrate
      })
    },
    toggleCheckAllCommission() {
      this.members.forEach((item) => {
        item.checkCommission = this.checkAllCommission
      })
    },
    async saveData() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      let res = await this.$swal({
        html: `การตั้งค่าจะส่งผลถึงสมาชิกทุกคน ที่อยู่ชั้นถัดลงไป`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })
      if (!res.isConfirmed) {
        return
      }

      this.userRefHuayCategoryPays.forEach((item) => {
        if (item.payrate) {
          item.checkPayrate = true
        }
        if (item.commission) {
          item.checkCommission = true
        }
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-category-pay",
          data: {
            userRefHuayCategoryPays: this.userRefHuayCategoryPays.filter(
              (item) => item.checkPayrate || item.checkCommission
            ),
            userHuayCategoryPays: this.userHuayCategoryPays,
            members: this.members.filter(
              (item) => item.checkPayrate || item.checkCommission
            ),
          },
        })
        await this.swal.swalSuccess()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      try {
        let res = await this.axios({
          method: "get",
          url: "users/category-pay-member-data",
          params: {
            userId: this.$route.params.userId,
            huayPayId: this.$route.query.payId || 0,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        let data = res.data.data

        this.huayPays = data.huayPays

        data.userHuayCategoryPays.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })
        this.userHuayCategoryPays = data.userHuayCategoryPays

        data.userRefHuayCategoryPays.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })

        data.userRefHuayCategoryPays.forEach((item) => {
          item.fixPayrate = item.payrate
          item.fixCommission = item.commission
          item.payrate = null
          item.commission = null
        })
        this.userRefHuayCategoryPays = data.userRefHuayCategoryPays

        data.members.forEach((item) => {
          item.checkPayrate = false
          item.checkCommission = false
        })
        this.members = data.members

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
