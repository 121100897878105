<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ยินดีต้อนรับ']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary">ข่าวสาร</h5>
      <div class="card-body ql-editor" id="news" v-html="news"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: "",
    }
  },
  async created() {
    this.swal.processing()

    try {
      let res = await this.axios({
        method: "get",
        url: "config/news",
      })

      this.news = res.data.data

      this.swal.close()
    } catch (e) {
      console.log(e)
      this.swal.swalError()
    }
  },
}
</script>