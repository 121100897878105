<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['จัดการสมาชิก', 'ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข']"
    />
    <user-nav name="ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showMemberName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showMemberName"
          />
          <label for="showMemberName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="ขั้นต่ำ"
              :active="!$route.query.page || $route.query.page == 'buyMin'"
              @click="setPage('buyMin')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th colspan="2">
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                          >
                            บันทึก
                          </button>
                          <button class="btn btn-danger" @click="clearBuyMin">
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMin"
                            @change="toggleCheckAllBuyMin"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`min_value:${item.fixBuyMin}|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.name }}</span>
                              <input
                                type="number"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMin"
                                v-if="showItem(item.status)"
                              />
                              <span v-if="showItem(item.status)">
                                >=
                                <span class="text-gold">
                                  {{ item.fixBuyMin }}
                                </span>
                              </span>
                              <span class="text-danger">
                                {{ v.errors[0] }}
                              </span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in members"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            v-if="
                              item.level == 'PARTNER' || item.level == 'AGENT'
                            "
                            :to="{
                              name: 'EditCategoryMemberByUserId',
                              params: { userId: item.id },
                              query: { page: 'buyMin' },
                            }"
                          >
                            {{ item.username }}
                          </router-link>
                          <span
                            v-if="
                              item.level != 'PARTNER' && item.level != 'AGENT'
                            "
                            >{{ item.username }}</span
                          >
                          <span v-if="showMemberName">({{ item.name }})</span>
                        </td>
                        <td>{{ item.levelTH }}</td>
                        <td>
                          <input type="checkbox" v-model="item.checkBuyMin" />
                        </td>
                        <td
                          v-for="(
                            itemBuyMin, indexBuyMin
                          ) in filterUserHuayCategorys(item.id)"
                          :key="indexBuyMin"
                        >
                          <span v-if="showItem(itemBuyMin.status)">{{
                            itemBuyMin.buyMin
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
              title="สูงสุดต่อโพย"
              :active="$route.query.page == 'buyMaxPerBill'"
              @click="setPage('buyMaxPerBill')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th colspan="2">
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                          >
                            บันทึก
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="clearBuyMaxPerBill"
                          >
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMaxPerBill"
                            @change="toggleCheckAllBuyMaxPerBill"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`max_value:${item.fixBuyMaxPerBill}|min_value:0|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.name }}</span
                              ><input
                                type="number"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMaxPerBill"
                                v-if="showItem(item.status)"
                              />
                              <span v-if="showItem(item.status)">
                                &#x3C;=
                                <span class="text-gold">
                                  {{ item.fixBuyMaxPerBill }}
                                </span>
                              </span>
                              <span class="text-danger">{{ v.errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in members"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            v-if="
                              item.level == 'PARTNER' || item.level == 'AGENT'
                            "
                            :to="{
                              name: 'EditCategoryMemberByUserId',
                              params: { userId: item.id },
                              query: { page: 'buyMaxPerBill' },
                            }"
                          >
                            {{ item.username }}
                          </router-link>
                          <span
                            v-if="
                              item.level != 'PARTNER' && item.level != 'AGENT'
                            "
                            >{{ item.username }}</span
                          >
                          <span v-if="showMemberName">({{ item.name }})</span>
                        </td>
                        <td>{{ item.levelTH }}</td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="item.checkBuyMaxPerBill"
                          />
                        </td>
                        <td
                          v-for="(
                            itemBuyMaxPerBill, indexBuyMaxPerBill
                          ) in filterUserHuayCategorys(item.id)"
                          :key="indexBuyMaxPerBill"
                        >
                          <span v-if="showItem(itemBuyMaxPerBill.status)">{{
                            itemBuyMaxPerBill.buyMaxPerBill
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
              title="สูงสุดต่อเลขต่อคน"
              :active="$route.query.page == 'buyMaxPerNumber'"
              @click="setPage('buyMaxPerNumber')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th colspan="2">
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                          >
                            บันทึก
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="clearBuyMaxPerNumber"
                          >
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMaxPerNumber"
                            @change="toggleCheckAllBuyMaxPerNumber"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`max_value:${item.fixBuyMaxPerNumber}|min_value:0|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.name }}</span
                              ><input
                                type="number"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMaxPerNumber"
                                v-if="showItem(item.status)"
                              />
                              <span v-if="showItem(item.status)">
                                &#x3C;=
                                <span class="text-gold">
                                  {{ item.fixBuyMaxPerNumber }}
                                </span>
                              </span>
                              <span class="text-danger">{{ v.errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in members"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            v-if="
                              item.level == 'PARTNER' || item.level == 'AGENT'
                            "
                            :to="{
                              name: 'EditCategoryMemberByUserId',
                              params: { userId: item.id },
                              query: { page: 'buyMaxPerNumber' },
                            }"
                          >
                            {{ item.username }}
                          </router-link>
                          <span
                            v-if="
                              item.level != 'PARTNER' && item.level != 'AGENT'
                            "
                            >{{ item.username }}</span
                          >
                          <span v-if="showMemberName">({{ item.name }})</span>
                        </td>
                        <td>{{ item.levelTH }}</td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="item.checkBuyMaxPerNumber"
                          />
                        </td>
                        <td
                          v-for="(
                            itemBuyMaxPerNumber, indexBuyMaxPerNumber
                          ) in filterUserHuayCategorys(item.id)"
                          :key="indexBuyMaxPerNumber"
                        >
                          <span v-if="showItem(itemBuyMaxPerNumber.status)">{{
                            itemBuyMaxPerNumber.buyMaxPerNumber
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-small {
  width: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      showMemberName: false,
      checkAllBuyMin: false,
      checkAllBuyMaxPerBill: false,
      checkAllBuyMaxPerNumber: false,
      huayCategorys: [],
      userHuayCategorys: [],
      members: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    showItem(status) {
      return status == "OPEN"
    },
    filterUserHuayCategorys(userId) {
      return this.userHuayCategorys.filter((el) => el.userId == userId)
    },
    clearBuyMin() {
      this.huayCategorys.forEach((item) => {
        item.buyMin = null
      })
    },
    clearBuyMaxPerBill() {
      this.huayCategorys.forEach((item) => {
        item.buyMaxPerBill = null
      })
    },
    clearBuyMaxPerNumber() {
      this.huayCategorys.forEach((item) => {
        item.buyMaxPerNumber = null
      })
    },
    toggleCheckAllBuyMin() {
      this.members.forEach((item) => (item.checkBuyMin = this.checkAllBuyMin))
    },
    toggleCheckAllBuyMaxPerBill() {
      this.members.forEach(
        (item) => (item.checkBuyMaxPerBill = this.checkAllBuyMaxPerBill)
      )
    },
    toggleCheckAllBuyMaxPerNumber() {
      this.members.forEach(
        (item) => (item.checkBuyMaxPerNumber = this.checkAllBuyMaxPerNumber)
      )
    },
    async saveCategorys() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      await this.$swal({
        icon: "warning",
        text: "การตั้งค่าจะส่งผลถึงสมาชิกทุกคน ที่อยู่ชั้นถัดลงไป",
      })

      this.huayCategorys.forEach((item) => {
        if (item.buyMin) {
          item.checkBuyMin = true
        }
        if (item.buyMaxPerBill) {
          item.checkBuyMaxPerBill = true
        }
        if (item.buyMaxPerNumber) {
          item.checkBuyMaxPerNumber = true
        }
      })

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "users/change-category",
          data: {
            userRefHuayCategorys: this.huayCategorys.filter(
              (item) =>
                item.checkBuyMin ||
                item.checkBuyMaxPerBill ||
                item.checkBuyMaxPerNumber
            ),
            userHuayCategorys: this.userHuayCategorys,
            members: this.members.filter(
              (item) =>
                item.checkBuyMin ||
                item.checkBuyMaxPerBill ||
                item.checkBuyMaxPerNumber
            ),
          },
        })

        let result = await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })

        if (result.isConfirmed) {
          this.checkAllBuyMin = false
          this.checkAllBuyMaxPerBill = false
          this.checkAllBuyMaxPerNumber = false
          this.initData()
        }
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
    async initData() {
      try {
        let res = await this.axios({
          mehotd: "get",
          url: "users/category-member-data",
          params: {
            userId: this.$route.params.userId,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        let data = res.data.data

        data.userRefHuayCategorys.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })
        data.userRefHuayCategorys.forEach((item) => {
          item.fixBuyMin = item.buyMin
          item.fixBuyMaxPerBill = item.buyMaxPerBill
          item.fixBuyMaxPerNumber = item.buyMaxPerNumber
          item.buyMin = null
          item.buyMaxPerBill = null
          item.buyMaxPerNumber = null
        })
        this.huayCategorys = data.userRefHuayCategorys
        data.userHuayCategorys.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })
        this.userHuayCategorys = data.userHuayCategorys
        data.members.forEach((item) => {
          item.checkBuyMaxPerBill = false
          item.checkBuyMaxPerNumber = false
          item.checkBuyMin = false
        })
        this.members = data.members
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
