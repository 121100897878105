<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'งวดหวยตามประเภท']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายละเอียดเฉพาะงวด
      </h5>
      <div class="card-body">
        <button
          class="btn btn-success mb-2"
          @click="$router.push('/setting/lotto-round')"
        >
          เพิ่มงวดหวย
        </button>
        <loading v-show="isLoading" />
        <div class="table-responsive" v-show="!isLoading">
          <table
            class="table table-bordered table-hover table-dark text-center"
          >
            <thead>
              <tr>
                <th>ชื่อ</th>
                <th>เปิด</th>
                <th>ปิด</th>
                <th>สถานะ</th>
                <th>3 บน</th>
                <th>3 ล่าง</th>
                <th>แก้ไข</th>
                <th>ปิดงวด</th>
                <th>ออกผล</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in huayRounds" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ $date(item.openTime).format("DD/MM/YYYY HH:mm") }}</td>
                <td>{{ $date(item.closeTime).format("DD/MM/YYYY HH:mm") }}</td>
                <td>{{ item.statusTH }}</td>
                <td>{{ item.back3Top == 0 ? "-" : item.back3Top }}</td>
                <td>{{ item.back3Bottom1 == 0 ? "-" : item.back3Bottom1 }}</td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="$router.push(`/setting/lotto-round/${item.id}`)"
                  >
                    แก้ไข
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-primary mr-2"
                    @click="updateOpenClose(item.id, 'OPEN')"
                  >
                    เปิด
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="updateOpenClose(item.id, 'CLOSE')"
                  >
                    ปิด
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="
                      $router.push(`/setting/lotto-result/add/${item.id}`)
                    "
                  >
                    ออกผล
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      huayRounds: [],
      // huayTypes: [],
      // huayGroups: [],
    }
  },
  methods: {
    getHuayTypes() {
      return this.huayTypes.filter(
        (item) => item.huayGroupId == this.form.huayGroupId
      )
    },
    updateOpenClose(id, status) {
      this.axios({
        method: "patch",
        url: "setting/huay-round/open-close",
        data: {
          id: id,
          status: status,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "สำเร็จ",
          }).then(() => {
            this.initData()
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    initData() {
      this.axios({
        method: "get",
        url: "setting/huay-round",
      })
        .then((res) => {
          let data = res.data.data
          this.huayRounds = data.huayRounds
          // this.huayTypes = data.huayTypes;
          // this.huayGroups = data.huayGroups;
          this.form = {}
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
  },
  mounted() {
    this.initData()
  },
}
</script>