<template>
  <div class="d-flex flex-column justify-content-center">
    <img
      src="~@/assets/logo.png"
      width="250"
      class="mx-auto"
      style="margin-top: 3rem"
    />
    <div
      class="card mx-auto"
      style="
        width: 300px;
        border-color: white;
        background-color: #5ce1e6 !important;
      "
    >
      <div class="card-body d-flex flex-column p-4">
        <h3 class="text-center mb-4">Agent</h3>
        <form @submit.prevent="handleLogin">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-user-alt mx-auto"></i>
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Username"
              v-model="user.username"
              required
            />
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-unlock-alt mx-auto"></i>
              </div>
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="user.password"
              required
            />
          </div>
          <button
            type="submit"
            class="btn btn-black border-white text-white w-100"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
body.login {
  background: rgb(225, 254, 255);
  background: linear-gradient(
    180deg,
    rgba(225, 254, 255, 1) 40%,
    rgba(65, 176, 196, 1) 85%
  );
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
</style>

<script>
export default {
  data() {
    return {
      user: {}
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  beforeCreate: function () {
    document.body.className = "login"
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/welcome")
    }
  },
  methods: {
    handleLogin() {
      this.swal.processing()
      this.$store.dispatch("auth/login", this.user).then(
        () => {
          this.$router.push("/welcome")
        },
        (error) => {
          this.$swal({
            icon: "error",
            title: "Login ไม่สำเร็จ",
            text: error.response ? error.response.data.description : ""
          })
        }
      )
    }
  }
}
</script>
