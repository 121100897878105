import Vue from "vue"
import VueRouter from "vue-router"
import Main from "@/layouts/Main.vue"
import Welcome from "@/views/Welcome.vue"
import Login from "@/views/Login.vue"
import Logout from "@/views/Logout.vue"

// Edit member
import AddMember from "@/views/member/AddMember"
import TypeShareMember from "@/views/member/TypeShareMember"
import EditMember from "@/views/member/EditMember"
import EditCategoryMember from "@/views/member/EditCategoryMember"
import EditPayMember from "@/views/member/EditPayMember"
import EditLottoOpenCloseMember from "@/views/member/EditLottoOpenCloseMember"
import TakeList from "@/views/member/TakeList"
import MemberTypePay from "@/views/member/MemberTypePay"

// Credit
import CreditTransfer from "@/views/credit/CreditTransfer"
import CreditHistory from "@/views/credit/CreditHistory"

// Setting
import LottoAddMultiRound from "@/views/setting/LottoAddMultiRound"
import LottoGroup from "@/views/setting/LottoGroup"
import LottoGroupForm from "@/views/setting/LottoGroupForm"
import LottoType from "@/views/setting/LottoType"
import LottoTypeForm from "@/views/setting/LottoTypeForm"
import LottoPeriodByType from "@/views/setting/LottoPeriodByType"
import LottoPeriodByDate from "@/views/setting/LottoPeriodByDate"
import PayRate from "@/views/setting/PayRate"
import PayRateForm from "@/views/setting/PayRateForm"
import System from "@/views/setting/System"
import LottoRound from "@/views/setting/LottoRound"
import LottoAddResult from "@/views/setting/LottoAddResult"

// Report
import ReportByDate from "@/views/report/ReportByDate"
import ReportByRound from "@/views/report/ReportByRound"
import ReportByItem from "@/views/report/ReportByItem"

// Item
import ItemCancelled from "@/views/item/ItemCancelled"
import ItemHalfPayout from "@/views/item/ItemHalfPayout"
import ItemLimitSetting from "@/views/item/ItemLimitSetting"
import ItemOverall from "@/views/item/ItemOverall"
import ItemSetting from "@/views/item/ItemSetting"

// Profile
import ProfileSetting from "@/views/profile/Setting"

import Dashboard from "@/views/Dashboard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  {
    path: "*",
    name: "Root",
    component: Main,
    redirect: "/welcome",
    children: [
      {
        path: "/welcome",
        name: "Welcome",
        component: Welcome
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "/credit",
        name: "Credit",
        component: {
          render(c) {
            return c("router-view")
          }
        },
        children: [
          {
            path: "transfer",
            name: "CreditTransfer",
            component: CreditTransfer
          },
          {
            path: "history",
            name: "CreditHistory",
            component: CreditHistory
          }
        ]
      },
      {
        path: "/members",
        name: "ManageMember",
        component: {
          render(c) {
            return c("router-view")
          }
        },
        children: [
          {
            path: "add",
            name: "AddMember",
            component: AddMember
          },
          {
            path: "share",
            name: "ShareMember",
            component: TypeShareMember
          },
          {
            path: "share/:userId",
            name: "ShareMemberByUserId",
            component: TypeShareMember
          },
          {
            path: "edit",
            name: "EditMember",
            component: EditMember
          },
          {
            path: "edit/:userId",
            name: "EditMemberByUserId",
            component: EditMember
          },
          {
            path: "edit-category",
            name: "EditCategoryMember",
            component: EditCategoryMember
          },
          {
            path: "edit-category/:userId",
            name: "EditCategoryMemberByUserId",
            component: EditCategoryMember
          },
          {
            path: "edit-pay",
            name: "EditPayMember",
            component: EditPayMember
          },
          {
            path: "edit-pay/:userId",
            name: "EditPayMemberByUserId",
            component: EditPayMember
          },
          {
            path: "edit-openclose-lotto",
            name: "EditLottoOpenCloseMember",
            component: EditLottoOpenCloseMember
          },
          {
            path: "edit-openclose-lotto/:userId",
            name: "EditLottoOpenCloseMemberByUserId",
            component: EditLottoOpenCloseMember
          },
          {
            path: "take-list",
            name: "TakeList",
            component: TakeList
          },
          {
            path: "take-list/:userId",
            name: "TakeListUser",
            component: TakeList
          },
          {
            path: "type-pay/:userId",
            name: "MemberTypePayByUserId",
            component: MemberTypePay
          },
          {
            path: "type-pay",
            name: "MemberTypePay",
            component: MemberTypePay
          }
        ]
      },
      {
        path: "/setting",
        name: "Setting",
        component: {
          render(c) {
            return c("router-view")
          }
        },
        children: [
          {
            path: "news",
            name: "SettingNews",
            component: () => import("@/views/setting/News.vue")
          },
          {
            path: "lotto-group",
            component: {
              render(c) {
                return c("router-view")
              }
            },
            children: [
              {
                path: "",
                name: "Lotto Group List",
                component: LottoGroup
              },
              {
                path: "add",
                name: "Lotto Group Add",
                component: LottoGroupForm
              },
              {
                path: "edit",
                name: "Lotto Group Edit",
                component: LottoGroupForm
              }
            ]
          },
          {
            path: "lotto-type",
            component: {
              render(c) {
                return c("router-view")
              }
            },
            children: [
              {
                path: "",
                name: "Lotto Type List",
                component: LottoType
              },
              {
                path: "add",
                name: "Lotto Type Add",
                component: LottoTypeForm
              },
              {
                path: "edit",
                name: "Lotto Type Edit",
                component: LottoTypeForm
              }
            ]
          },
          // {
          //   path: "lotto-category",
          //   component: {
          //     render(c) {
          //       return c("router-view");
          //     }
          //   },
          //   children: [
          //     {
          //       path: "",
          //       name: "Lotto Category List",
          //       component: LottoCategory
          //     },
          //     {
          //       path: "add",
          //       name: "Lotto Category Add",
          //       component: LottoCategoryForm
          //     },
          //     {
          //       path: "edit",
          //       name: "Lotto Category Edit",
          //       component: LottoCategoryForm
          //     }
          //   ]
          // },
          {
            path: "lotto-period-by-type",
            name: "Lotto Period By Type",
            component: LottoPeriodByType
          },
          {
            path: "lotto-period-by-date",
            name: "Lotto Period By Date",
            component: LottoPeriodByDate
          },
          {
            path: "pay-rate",
            component: {
              render(c) {
                return c("router-view")
              }
            },
            children: [
              {
                path: "",
                name: "Pay Rate List",
                component: PayRate
              },
              {
                path: "add",
                name: "Pay Rate Add",
                component: PayRateForm
              },
              {
                path: "edit",
                name: "Pay Rate Edit",
                component: PayRateForm
              }
            ]
          },
          {
            path: "lotto-add-multi-round",
            name: "LottoAddMultiRound",
            component: LottoAddMultiRound
          },
          {
            path: "system",
            name: "System",
            component: System
          },
          {
            path: "lotto-round/:id",
            name: "LottoRoundUpdate",
            component: LottoRound
          },
          {
            path: "lotto-round",
            name: "LottoRoundCreate",
            component: LottoRound
          },
          {
            path: "lotto-result/add/:id",
            name: "LottoAddResult",
            component: LottoAddResult
          }
        ]
      },
      {
        path: "/reports",
        name: "Report",
        component: {
          render(c) {
            return c("router-view")
          }
        },
        children: [
          {
            path: "game-result",
            name: "ReportGameResult",
            component: () => import("@/views/report/ReportGameResult.vue")
          },
          {
            path: "by-date",
            name: "ReportByDate",
            component: ReportByDate
          },
          {
            path: "by-date/:userId",
            name: "ReportUserByDate",
            component: ReportByDate
          },
          {
            path: "by-date/of-member/:userId",
            name: "ReportByDateOfMember",
            component: ReportByItem
          },
          {
            path: "by-member",
            name: "ReportByMember",
            component: ReportByRound
          },
          {
            path: "by-member/:userId",
            name: "ReportUserByMember",
            component: ReportByRound
          },
          {
            path: "by-member/of-number/:userId",
            name: "ReportByMemberOfNumber",
            component: ReportByItem
          },
          {
            path: "winning",
            name: "ReportWinning",
            component: ReportByItem
          }
        ]
      },
      {
        path: "/items",
        name: "Item",
        component: {
          render(c) {
            return c("router-view")
          }
        },
        children: [
          {
            path: "by-date",
            name: "ReportWaitingByDate",
            component: ReportByDate
          },
          {
            path: "by-date/:userId",
            name: "ReportWaitingUserByDate",
            component: ReportByDate
          },
          {
            path: "by-round",
            name: "ItemByRound",
            component: ReportByRound
          },
          {
            path: "by-round/:userId",
            name: "ItemUserByRound",
            component: ReportByRound
          },
          {
            path: "cancelled",
            name: "ItemCancelled",
            component: ItemCancelled
          },
          {
            path: "half-payouts",
            name: "ItemHalfPayout",
            component: ItemHalfPayout
          },
          {
            path: "limit-settings",
            name: "ItemLimitSetting",
            component: ItemLimitSetting
          },
          {
            path: "overall",
            name: "ItemOverall",
            component: ItemOverall
          },
          {
            path: "overall/:userId",
            name: "ItemOverallUser",
            component: ItemOverall
          },
          {
            path: "of-number/:categoryId/:number",
            name: "ItemOfNumber",
            component: ReportByItem
          },
          {
            path: "by-date/of-member/:userId",
            name: "ItemByDateOfMember",
            component: ReportByItem
          },
          {
            path: "of-number/:userId",
            name: "ItemOfMember",
            component: ReportByItem
          },
          {
            path: "settings",
            name: "ItemSetting",
            component: ItemSetting
          }
        ]
      },
      {
        path: "profile/setting",
        name: "Profile Setting",
        component: ProfileSetting
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  next()
})

export default router
