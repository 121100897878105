<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['จัดการสมาชิก', 'อัตราจ่ายตามชนิดหวย']"
    />
    <user-nav name="อัตราจ่ายตามชนิดหวย"></user-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-if="!isLoading && !isNotFound">
      <div class="alert alert-info" role="alert">
        สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
      </div>
      <div class="d-flex mb-1">
        <input
          id="showUserName"
          type="checkbox"
          class="cursor-pointer mr-1 my-auto"
          v-model="showUserName"
        />
        <label for="showUserName" class="my-auto">แสดงชื่อ</label>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="huayGroup">กลุ่มหวย</label>
            <select
              id="huayGroup"
              class="form form-control w-auto"
              v-model="huayGroup"
            >
              <option disabled>เลือกกลุ่มหวย</option>
              <option
                v-for="(huayGroupItem, huayGroupItemIndex) in huayGroups"
                :key="huayGroupItemIndex"
                :value="huayGroupItem"
              >
                {{ huayGroupItem.name }}
              </option>
            </select>
          </div>
          <div v-if="huayGroup" class="form-group">
            <label for="huayType">หวย</label>
            <select
              id="huayType"
              class="form form-control w-auto"
              v-model="huayTypeItem"
            >
              <option disabled>เลือกหวย</option>
              <option
                v-for="(huayTypeItem, huayTypeItemIndex) in filterHuayType(
                  huayGroup.id
                )"
                :key="huayTypeItemIndex"
                :value="huayTypeItem"
              >
                {{ huayTypeItem.name }}
              </option>
            </select>
          </div>
          <div v-if="huayTypeItem" class="form-group">
            <label for="pay">อัตราจ่าย</label>
            <select
              id="pay"
              class="form form-control w-auto"
              v-model="userRefHuayTypePayItem"
            >
              <option disabled>เลือกอัตราจ่าย</option>
              <option
                v-for="(
                  userRefHuayTypePay, userRefHuayTypePayIndex
                ) in filterUserRefHuayTypePay(huayTypeItem.id)"
                :key="userRefHuayTypePayIndex"
                :value="userRefHuayTypePay"
              >
                {{ userRefHuayTypePay.payName }}
              </option>
            </select>
          </div>
          <div v-if="userRefHuayTypePayItem" class="table-responsive mt-3">
            <table
              class="
                table table-bordered table-hover table-dark
                text-center
                fs-075
              "
            >
              <thead>
                <tr>
                  <th>
                    <button
                      class="btn btn-success mr-1"
                      @click="setStatusUserHuayTypePay('OPEN')"
                    >
                      เปิด
                    </button>
                    <button
                      class="btn btn-danger mr-1"
                      @click="setStatusUserHuayTypePay('CLOSE')"
                    >
                      ปิด
                    </button>
                  </th>
                  <th>
                    <input
                      type="checkbox"
                      v-model="checkAllUserHuayTypePay"
                      @change="
                        toggleCheckAllUserHuayTypePay(
                          huayTypeItem.id,
                          userRefHuayTypePayItem.huayPayId
                        )
                      "
                    />
                  </th>
                  <th>สถานะ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    userHuayTypePay, userHuayTypePayIndex
                  ) in filterUserHuayTypePay(
                    huayTypeItem.id,
                    userRefHuayTypePayItem.huayPayId
                  )"
                  :key="userHuayTypePayIndex"
                  :class="{
                    'table-danger': userHuayTypePay.userStatus == 'INACTIVE',
                  }"
                >
                  <td class="text-left">
                    <router-link
                      v-if="
                        userHuayTypePay.userLevel == 'PARTNER' ||
                        userHuayTypePay.userLevel == 'AGENT'
                      "
                      :to="{
                        name: 'MemberTypePayByUserId',
                        params: {
                          userId: userHuayTypePay.userId,
                        },
                      }"
                    >
                      {{ userHuayTypePay.username }}
                    </router-link>
                    <span
                      v-if="
                        userHuayTypePay.userLevel != 'PARTNER' &&
                        userHuayTypePay.userLevel != 'AGENT'
                      "
                      >{{ userHuayTypePay.username }}</span
                    >
                    <span v-show="showUserName"
                      >({{ userHuayTypePay.userName }})</span
                    >
                  </td>
                  <td>
                    <input type="checkbox" v-model="userHuayTypePay.check" />
                  </td>
                  <td>
                    {{ userHuayTypePay.status == "OPEN" ? "เปิด" : "ปิด" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUserName: false,
      isLoading: true,
      isNotFound: false,
      huayGroups: [],
      huayTypes: [],
      userRefHuayTypePays: [],
      userHuayPays: [],
      checkAllUserHuayTypePay: false,
      huayGroup: null,
      huayTypeItem: null,
      userRefHuayTypePayItem: null,
    }
  },
  watch: {
    huayGroup() {
      this.huayTypeItem = null
      this.userRefHuayTypePayItem = null
    },
    huayTypeItem() {
      this.userRefHuayTypePayItem = null
    },
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    toggleCheckAllUserHuayTypePay(huayTypeId, huayPayId) {
      this.userHuayTypePays.forEach((item) => {
        if (item.huayTypeId === huayTypeId && item.huayPayId == huayPayId) {
          item.check = this.checkAllUserHuayTypePay
        }
      })
    },
    filterUserRefHuayTypePay(huayTypeId) {
      return this.userRefHuayTypePays.filter(
        (item) => item.huayTypeId === huayTypeId
      )
    },
    filterHuayType(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId === huayGroupId)
    },
    filterUserHuayTypePay(huayTypeId, huayPayId) {
      return this.userHuayTypePays.filter(
        (item) => item.huayTypeId === huayTypeId && item.huayPayId == huayPayId
      )
    },
    async setStatusUserHuayTypePay(status) {
      this.swal.processing()

      const userHuayTypePays = this.userHuayTypePays
        .filter((item) => item.check)
        .map((item) => {
          item.status = status
          return item
        })

      try {
        await this.axios({
          method: "patch",
          url: "users/type-pay",
          data: {
            userHuayTypePays,
          },
        })

        await this.swal.swalSuccess()
      } catch (e) {
        await this.swal.swalError()
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      this.isLoading = true

      try {
        const res = await this.axios({
          method: "get",
          url: "users/type-pay",
          params: {
            userId: this.$route.params.userId,
          },
        })

        const data = res.data.data

        if (data.users.length === 0) {
          this.isNotFound = true
        } else {
          this.huayGroups = data.huayGroups
          this.huayTypes = data.huayTypes
          this.userRefHuayTypePays = data.userRefHuayTypePays

          const mapUsers = new Map()
          data.users.forEach((item) => mapUsers.set(item.id, item))
          data.userHuayTypePays.forEach((item) => {
            const user = mapUsers.get(item.userId)
            item.username = user.username
            item.userName = user.name
            item.userLevel = user.level
            item.userStatus = user.status
            item.check = false
          })

          this.userHuayTypePays = data.userHuayTypePays
        }

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
